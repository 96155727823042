import BaseBean from "@/utils/BaseBean";
import {nextTick} from 'vue'
export interface IDialogDataObj {
    utilInst:DialogUtil
    refMap:Map<string,any>
    customClass:string
    modelType:any
    engineRef:any
    engine:any
    engineParams:any
    fullscreenLoading: boolean
    options: any
    dialogVisible: boolean
    otherParams:any
}
export default class DialogUtil extends BaseBean{
    public dataObj:IDialogDataObj;
    constructor(proxy:any,dataObj:IDialogDataObj) {
        super(proxy);
        this.dataObj=dataObj;
    }

    public initDialogParams(engineParams:any):void{
        nextTick(()=>{
            let modelCompProp:any={};
            if(engineParams.modelComp && engineParams.modelComp.title!= undefined)modelCompProp.title=engineParams.modelComp.title;

            if(engineParams.modelComp && engineParams.modelComp.fullscreen!= undefined)modelCompProp.fullscreen=engineParams.modelComp.fullscreen;

            if(engineParams.modelComp && engineParams.modelComp.dWidth!= undefined)modelCompProp.dWidth=engineParams.modelComp.dWidth;

            if(engineParams.modelComp && engineParams.modelComp.showMaxRecover!= undefined)modelCompProp.showMaxRecover=engineParams.modelComp.showMaxRecover;
            Object.assign(this.dataObj.options,engineParams,modelCompProp);
        })
    }
    public buildDialogContain(engineParams:any):void{

         let modelType=engineParams.modelType?engineParams.modelType:engineParams.modelComp.modelType;
         this.dataObj.modelType=modelType;
         if(!modelType || modelType=='list'){
             this.dataObj.engine=engineParams.modelComp;

             nextTick(()=>{
                 if(!this.dataObj.options.fullscreen) {
                     this.cfgListEngineContentHeight(false);
                 }
             })
         }else{
             if('card'===modelType){
                 if('true'==sessionStorage.getItem('needMultiLanguage'))engineParams.modelComp.title=this.proxy.$t(engineParams.modelComp.name);//哎，这这里国际化卡片的标题吧
                 import("@/components/base_comp/cardEngine/CardEngine.vue").then(component=>this.dataObj.engine=component.default);
             }if('bill'===modelType){

             }else if('link'===modelType){
                 import("@/components/base_comp/linkComponent/LinkComponent.vue").then(component=>this.dataObj.engine=component.default);
             }
         }
     }

    public cfgListEngineContentHeight(reCalFlag:boolean):void{
        if(this.dataObj.modelType && this.dataObj.modelType=='list' && this.dataObj.options.contentHeight){
            let contentHeight=this.dataObj.options.contentHeight;
            if(contentHeight.indexOf('px')>0)contentHeight=contentHeight.substr(0,contentHeight.indexOf('px'));
            this.dataObj.engineRef.pageListRef.utilInst.setSpeHeight((parseInt(contentHeight)+this.utils.Const.topHeight+this.utils.Const.tabHeight),reCalFlag);
        }
    }
}