import UploadUtil,{IUploadDataObj} from "@/components/base_comp/upload/uploadUtil";
import {ref, reactive, toRefs, onBeforeMount, onMounted, defineComponent, getCurrentInstance, inject, watch, nextTick, computed} from 'vue';
import {UploadFile, UploadFiles, UploadRequestOptions} from "element-plus/lib/components";

export default defineComponent({
    name: "UploadComp",
    props: {
        beforeRemove: {
            type: Function, default: async (file:UploadFile, fileList:UploadFiles) => {return true;}
        },
        afterRemove: {
            type: Function, default: async (file:UploadFile, fileList:UploadFiles) => {return true;}
        },
    },
    setup(props,context){
        const {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        let uploadOwner = inject('uploadOwner') as any;
        const uploadComp=ref(null);
        let dataObj:IUploadDataObj=reactive<IUploadDataObj>({
            utilInst:{} as any,
            refMap:new Map(),
            resultMsg:"",
            uploadDialogVisible:false,
            dialogImageUrl:'',
            fileList:[],
            uploadParams:{
                title:proxy.$t('upload.upload'),
                showFileList:false,
                disabled:false,
                listType:'text',
                multiple:true,
                drag:false,
                limitNum:5,
                accept:'',
                autoUpload:true,

                uploadType:'commonFile',
                aliCloudPre:'',
                type:1,
                saveType:utils.Tools.getPropFromVuex('uploadSaveType'),
                action:'',
                showInDialog:true,
                canDelFile:true,
                fileSize:10,
                belongMaxId:'',
                belongMinId:'',
                uploadBtnType:0,
                iconStyle:'cursor: pointer;font-size: 20px;color: #2980b9;width: 100%;'
            },
            uploadResultDrawer:false,
            otherParams:{
                uploadPercentage:100
            }
        });
        onBeforeMount(()=>{
            dataObj.utilInst=new UploadUtil(proxy,dataObj,props,context);
        })
        onMounted(async ()=>{
            Object.assign(dataObj.uploadParams, context.attrs);
            switch (dataObj.uploadParams.uploadBtnType) {
                case 1:
                    const uploadBtn:any = (uploadComp.value as any).$el.querySelector('.el-upload');
                    uploadBtn.style = 'width:100%;height: 100px;line-height: 100px;text-align: center;box-shadow: 3px 3px 4px 4px grey;border-radius: 5px;';

                    break;
            }
        });
        watch(() => dataObj.uploadParams.belongMaxId,async (newValue,oldValue) => {
            if(newValue)await loadFileList(dataObj.uploadParams);
        })

        const doUpload=async (params:UploadRequestOptions)=>{
            await dataObj.utilInst.doUpload(params);
        }

        const disableUpload=(disabled:boolean)=>{
            dataObj.uploadParams.disabled=disabled;
        }

        const handlePreview=async (file:UploadFile)=>{
            if (uploadOwner && uploadOwner.handlePreview) {
                await uploadOwner.handlePreview(file);
            }else{
                if(dataObj.uploadParams.showInDialog){
                    dataObj.uploadDialogVisible=true;
                    dataObj.dialogImageUrl=file.url as string;
                }
            }
        }

        const handleExceed=(files:File[], fileList:UploadFiles)=>{
            proxy.$message.warning(proxy.$t('upload.limitNumPre')+dataObj.uploadParams.limitNum+proxy.$t('upload.limitNumSuf'));
        }

        const uploadOnSuccess=(response:any, file:UploadFile, fileList:UploadFiles)=>{
            dataObj.fileList.push({name:file.name,uid:file.uid});
            if(uploadOwner && uploadOwner.uploadOnSuccess && !uploadOwner.uploadOnSuccess(response, file, fileList,proxy))return false;
        }

        const picUrlList=computed(()=>{
            let list:Array<string>=[];
            dataObj.fileList.forEach((item:any)=>{
                list.push(item.url);
            });
            return list;
        })
        const loadFileList=async (options:any)=>{
            let res=await utils.Api.loadByBelongMaxIdAndType(options);
            dataObj.fileList.length=0;
            res.uploadFiles.forEach((item:any)=>{
                let path=item.path;
                if(item.saveType==0){
                    if(path.indexOf('/billAttach')>-1){
                        path=path.substr(path.indexOf('/billAttach'));
                    }else if(path.indexOf('/otherFiles')>-1){
                        path=path.substr(path.indexOf('/otherFiles'));
                    }
                    path=utils.Tools.getAloneServerInfo()+path;
                }
                dataObj.fileList.push({name:item.preName,url:path,id:item.id});
            })
        }
        return{
            ...toRefs(dataObj),uploadComp,doUpload,handlePreview,disableUpload,
            handleExceed,uploadOnSuccess,picUrlList,loadFileList
        }
    }
})