
import {ref, reactive, toRefs, computed, defineComponent,onBeforeMount, onMounted, getCurrentInstance, watch, provide, inject} from 'vue';
import EditTableUtil,{IEditTableDataObj} from "@/components/base_comp/editTable/editTableUtil";
const EditTableHelper = defineComponent({
    name: "EditTable",
    props: {
        parentInst: {
            type: Object,
            default: null
        },
        beganEdit: {
            type: Function, default: async (options:any) => {return true;}
        },
        afterBeganEdit: {
            type: Function, default: async (options:any) => {return true;}
        },
        beforeAdd: {
            type: Function, default: async (options:any) => {return true;}
        },
        beforeDel: {
            type: Function, default: async (options:any) => {return true;}
        },
        afterDel: {
            type: Function, default: async (options:any) => {return true;}
        },
        beforeEndEdit: {
            type: Function, default: async (options:any) => {return true;}
        },
        beforeClearData: {
            type: Function, default: async (options:any) => {return true;}
        },
        afterClearData: {
            type: Function, default: async (options:any) => {return true;}
        },
        gridLoaded: {
            type: Function, default: async (res:any) => {
                res.rows.forEach((row:any)=> {row.EDIT = false;});
            }
        },
    },
    setup(props,context){
        let {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        provide('gridTableOwner', proxy);
        let _detailParam:any=context.attrs.detailParam;
        let dataObj:IEditTableDataObj=reactive<IEditTableDataObj>({
            utilInst:{} as any,
            editParams: {idField: "F_ID",title:proxy.$t('editTable.title'),canAdd: true,canUp:false,canDown:false,readOnly: false,isShowFixCol: true,dragRow:false,toolBar: [],showTitle:true,showToolBar:true},//明细表格的参数
            fields: [],
            insert: [],
            update: [],
            delete: [],
            detailTypeRef:null,
            detailType: _detailParam.queryParam.detailType
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new EditTableUtil(proxy,dataObj,props,context);

            dataObj.editParams = Object.assign({autoLoad:false,tbId:dataObj.detailType,dragParent:dataObj.detailType},dataObj.editParams,_detailParam);
        })
        onMounted(()=>{

            dataObj.fields=dataObj.detailTypeRef.tbCols.fields;
        })
        const clickHandler=(ev:any, param:any)=>{

            if (dataObj.utilInst.editTableOwner[ev]) {
                dataObj.utilInst.editTableOwner[ev](param);
            } else {
                proxy[ev](param);
            }
        }
        const setReadOnly=async (val:boolean)=>{
            dataObj.editParams.readOnly=val;
            await dataObj.utilInst.setAllRowCanNotEdit;
        }

        const gridLoaded=async(res:any)=>{
            await dataObj.utilInst.gridLoaded(res);
        }
        const addDetail=async ()=>{
            await dataObj.utilInst.addDetail();
        }
        const delDetail=async (row:any, index:number)=>{
            await dataObj.utilInst.delDetail(row,index);
        }
        const rowClickHandler=async (row:any)=>{
            await dataObj.utilInst.clickTableRowHandler({row:row});
        }
        const clearDetailData=async ()=>{
            await dataObj.utilInst.clearDetailData();
        }
        const up=async ()=>{
            await dataObj.utilInst.up();
        }
        const down=async ()=>{
            await dataObj.utilInst.down(context);
        }
        const getTbInst=()=>{
            return dataObj.detailTypeRef;
        }
        const getDetailData=()=>{
            return dataObj.detailTypeRef.tbData;
        }
        const reloadGrid=async(params:any)=>{
            await dataObj.detailTypeRef.queryHandler(true, params);
        }
        const getSaveDetailData=async()=>{
            if(!await dataObj.utilInst.setAllRowCanNotEdit())return null;
            return {
                insert: [...new Set(dataObj.insert)],
                update: [...new Set(dataObj.update)],
                delete: [...new Set(dataObj.delete)]
            };
        }
        const addRow=(row:any)=>{
            dataObj.detailTypeRef.tbData.push(row);
            dataObj.insert.push(row);
        }
        const setInsert=(row:any)=>{
            dataObj.insert.push(row);
        }
        const setUpdate=(row:any)=>{
            dataObj.update.push(row);
        }
        const setDelete=(id:any)=>{
            dataObj.delete.push(id);
        }
        const setRowData=(row:any, index:number)=>{
            dataObj.detailTypeRef.tbData[index] = row;
        }
        const setCellData=(field:string,value:any, index:number)=>{
            dataObj.detailTypeRef.tbData[index][field]=value;
        }
        const getEditRow=()=>{
            let tbData = dataObj.detailTypeRef.tbData;
            return tbData.find((row:any,index:number,tbData:any)=>{
                return tbData[index].EDIT==true;
            });
        }

        return{
            ...toRefs(dataObj),clickHandler,setReadOnly,addDetail,delDetail,gridLoaded,
            rowClickHandler,getDetailData,clearDetailData,reloadGrid,getSaveDetailData,
            addRow,setInsert,setUpdate,setDelete,setRowData,setCellData,getTbInst,getEditRow,up,down
        }
    },

    components: {}
});
export default EditTableHelper;