import Tools from "@/utils/Tools";
import Api from "@/utils/api/Api";
import config from "@/utils/config";
import UtilClient from "@/utils/UtilClient";
export default class Websocket{
    static createWebSocketCon(usercode:string,appInstant:any):Promise<any>{
        return new Promise((resolve:any, reject:any) => {
            let baseURL=config.baseURL as string;

            baseURL=baseURL.replace("http","ws");
            let result=Object.assign(UtilClient.browserInfo(),{systemType:UtilClient.getSystemType()});

            let params;
            for (let key in result) {
                if(params){
                    params=params+'&'+key+'='+result[key];
                }else{
                    params=key+'='+result[key];
                }
            }
            let applicationArr=config.applications;
            let ranIndex=Math.floor(Math.random()*applicationArr.length);//随机生成下标
            let moduleName=applicationArr[ranIndex];
            let url=baseURL+'/'+moduleName+'/websocketServer/'+usercode+'/'+params;
            let $socket = new WebSocket(url);
            appInstant.appContext.config.globalProperties.$socket=$socket;
            resolve();
        });
    }
    static initWebSocket(appInstant:any):Promise<any>{
        return new Promise((resolve:any, reject:any) => {
            let $socket=appInstant.appContext.config.globalProperties.$socket;
            $socket.onopen = Websocket.webSocketOnOpen;//连接成功方法
            $socket.onerror = Websocket.webSocketOnError;//报错方法
            $socket.onmessage = Websocket.webSocketOnMessage;// 接收服务端返回或推送信息的方法
            $socket.onclose = Websocket.webSocketClose;//关闭
            resolve();
        });
    }
    static webSocketOnOpen(e:any):void{
        console.log('WebSocket连接成功');
    }
    static webSocketOnError(e:any):void{
        console.log('WebSocket出错了', e);
    }
    // 接收服务端发送过来的信息，整个项目接收信息的唯一入口
    static async webSocketOnMessage(e:any):Promise<any>{
        if (!e.data)return;
        let data=JSON.parse(e.data);
        switch (data.msgType) {
            case 1://实时在线消息
                let params = Object.assign({duration:0}, data);
                Tools.info(params);
                break;
            case 2://强制退出
                sessionStorage.removeItem("userInfo");
                let res = await Api.loginOut();
                if(res.result){
                    Tools.success({message: "成功退出登录!"});
                    location.reload();
                }
                break;
            case 3://弹出框
                Tools.ctx.appInst.noteDialog=true;
                Tools.ctx.appInst.dialogTitle='登录成功提示';
                Tools.ctx.appInst.dialogContent=data.msg;
                break;
        }
    }
    static webSocketClose(e:any):void{
        console.log('断开连接', e);
    }
}