import BaseBean from "@/utils/BaseBean";

export interface IImportGuidDataObj {
    utilInst:ImportGuidUtil
    importDataDialogVisible:boolean
    uploadParams:any
    refMap:Map<string,any>
}

export default class ImportGuidUtil extends BaseBean{
    public dataObj:IImportGuidDataObj

    constructor(proxy:any,dataObj:IImportGuidDataObj) {
        super(proxy);
        this.dataObj=dataObj;
    }

}