export default class UtilClient{
     //浏览器名称及版本
    static browserInfo():any{
        let userAgent=window.navigator.userAgent,
            rMsie = /(msie\s|trident.*rv:)([\w.]+)/,
            rFirefox = /(firefox)\/([\w.]+)/,
            rOpera = /(opera).+version\/([\w.]+)/,
            rChrome = /(chrome)\/([\w.]+)/,
            rSafari = /version\/([\w.]+).*(safari)/;
        userAgent=userAgent.toLowerCase();
        let match = rMsie.exec(userAgent);
        if (match != null) {
            return {
                browser: "IE",
                version: match[2] || "0"
            };
        }
        match = rFirefox.exec(userAgent);
        if (match != null) {
            return {
                browser: match[1] || "",
                version: match[2] || "0"
            };
        }
        match = rOpera.exec(userAgent);
        if (match != null) {
            return {
                browser: match[1] || "",
                version: match[2] || "0"
            };
        }
        match = rChrome.exec(userAgent);
        if (match != null) {
            return {
                browser: match[1] || "",
                version: match[2] || "0"
            };
        }
        match = rSafari.exec(userAgent);
        if (match != null) {
            return {
                browser: match[2] || "",
                version: match[1] || "0"
            };
        }
        if (!match) {
            return {
                browser: "",
                version: "0"
            };
        }
    }
    //操作系统类型
    static getSystemType():any{
        let sUserAgent = navigator.userAgent;
        let isWin = (navigator.platform == "Win32") || (navigator.platform == "Windows");
        let isMac = (navigator.platform == "Mac68K") || (navigator.platform == "MacPPC") || (navigator.platform ==
            "Macintosh") || (navigator.platform == "MacIntel");
        if (isMac) return "Mac";
        let isUnix = (navigator.platform == "X11") && !isWin && !isMac;
        if (isUnix) return "Unix";
        let isLinux = (String(navigator.platform).indexOf("Linux") > -1);
        if (isLinux) return "Linux";
        if (isWin) {
            let isWin2K = sUserAgent.indexOf("Windows NT 5.0") > -1 || sUserAgent.indexOf("Windows 2000") > -1;
            if (isWin2K) return "Win2000";
            let isWinXP = sUserAgent.indexOf("Windows NT 5.1") > -1 || sUserAgent.indexOf("Windows XP") > -1;
            if (isWinXP) return "WinXP";
            let isWin2003 = sUserAgent.indexOf("Windows NT 5.2") > -1 || sUserAgent.indexOf("Windows 2003") > -1;
            if (isWin2003) return "Win2003";
            let isWinVista = sUserAgent.indexOf("Windows NT 6.0") > -1 || sUserAgent.indexOf("Windows Vista") > -1;
            if (isWinVista) return "WinVista";
            let isWin7 = sUserAgent.indexOf("Windows NT 6.1") > -1 || sUserAgent.indexOf("Windows 7") > -1;
            if (isWin7) return "Win7";
            let isWin10 = sUserAgent.indexOf("Windows NT 10") > -1 || sUserAgent.indexOf("Windows 10") > -1;
            if (isWin10) return "Win10";
        }
        return "other";
    }
}