import BaseBean from "@/utils/BaseBean";

export interface IComboTreeDataObj {
    utilInst:ComboTreeUtil
    refMap:Map<string,any>
    visible:boolean
    loading:boolean
    readOnly:boolean

    comboText: string
    mapTreeValueText:Map<string,any>
    isLoaded: boolean
    modelMethod:string
    data: undefined,
    defaultProps:any
    baseParams:any
    comboParam:any
    otherParams:any
}
export default class ComboTreeUtil extends BaseBean{
    public dataObj:IComboTreeDataObj
    public props:any
    public context:any

    constructor(proxy:any,dataObj:IComboTreeDataObj,props:any,context:any) {
        super(proxy);
        this.dataObj=dataObj
        this.props=props
        this.context=context
    }
    public init(comboParam:any):void{
        Object.assign(this.dataObj.baseParams,comboParam);
        if(this.dataObj.baseParams.readOnly)this.dataObj.readOnly=this.dataObj.baseParams.readOnly;
        if(this.dataObj.baseParams.defaultProps)Object.assign(this.dataObj.defaultProps,this.dataObj.baseParams.defaultProps);
        if(this.dataObj.baseParams.showCheckbox)this.dataObj.baseParams.expandAll=true;
    }

    public async getTextById(modelValue:any,comboParam:any):Promise<void>{
        if (this.utils.UtilPub.isNotEmpty(modelValue)) {
            if(this.dataObj.baseParams.comboText){
                this.dataObj.comboText = this.dataObj.baseParams.comboText;
            }else{
                let modelPath=this.dataObj.modelMethod+"/custom";
                let params= Object.assign({},comboParam,{id:modelValue,comboType:'comboTree',operateType:'getTextById'});
                const res=await this.utils.Api.postRequest({url: modelPath,params: params});
                if(res.result)this.dataObj.comboText = res.comboText;
            }
        }
    }
    public async emitEvent(eventName:string,...params:any):Promise<void>{

        if(params)params[params.length]=this.dataObj.comboParam.comboId;
        else params[0]=this.dataObj.comboParam.comboId;

        if(this.props.bindEvent[eventName]){
            if(params)await this.props.bindEvent[eventName](...params);
            else await this.props.bindEvent[eventName]();
        }
        else{
            if(params) this.context.emit(eventName,...params);//触发事件
            else this.context.emit(eventName);//触发事件
        }
    }
}