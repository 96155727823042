import BaseBean from "@/utils/BaseBean";

export interface ISplitDataObj {
    utilInst:SplitUtil
    refMap:Map<string,any>
    otherParams:any
}

export default class SplitUtil extends BaseBean{
    public dataObj:ISplitDataObj

    constructor(proxy:any,dataObj:ISplitDataObj) {
        super(proxy);
        this.dataObj=dataObj;
    }
    //pc
    public drag():void{
        if(!this.dataObj.refMap.get('resize'))return;

        this.dataObj.refMap.get('resize').onmousedown =  (e:any) =>{
            let startX = e.clientX;
            this.dataObj.refMap.get('resize').left = this.dataObj.refMap.get('resize').offsetLeft;

            document.onmousemove =  (e)=> {
                let endX = e.clientX;
                let moveLen = this.dataObj.refMap.get('resize').left + (endX - startX);
                let maxT = this.dataObj.refMap.get('box').clientWidth - this.dataObj.refMap.get('resize').offsetWidth;

                if (moveLen < this.dataObj.otherParams.lMinWidth) moveLen = this.dataObj.otherParams.lMinWidth;
                if (moveLen > maxT - this.dataObj.otherParams.rMinWidth) moveLen = maxT - this.dataObj.otherParams.rMinWidth;

                this.dataObj.refMap.get('resize').style.left = moveLen;
                this.dataObj.refMap.get('left').style.width = moveLen + 'px';
                this.dataObj.refMap.get('right').style.width = (this.dataObj.refMap.get('box').clientWidth - moveLen - 10) + 'px';
            };
            document.onmouseup =  (evt)=> {
                document.onmousemove = null;
                document.onmouseup = null;
                this.dataObj.refMap.get('resize').releaseCapture && this.dataObj.refMap.get('resize').releaseCapture();
                this.dataObj.otherParams.lwidth=Math.floor((this.dataObj.refMap.get('left').clientWidth/this.dataObj.refMap.get('box').clientWidth)*100+1)+"%";
            };
            this.dataObj.refMap.get('resize').setCapture && this.dataObj.refMap.get('resize').setCapture();
            return false;
        };
    }
    public phoneDrag():void{
        if(!this.dataObj.refMap.get('resize'))return;
        this.dataObj.refMap.get('resize').ontouchstart  =  (e:any) =>{
            let startX = e.touches[0].clientX;
            this.dataObj.refMap.get('resize').left = this.dataObj.refMap.get('resize').offsetLeft;
            document.ontouchmove =  (e)=> {
                let endX = e.touches[0].clientX;
                let moveLen = this.dataObj.refMap.get('resize').left + (endX - startX);
                let maxT = this.dataObj.refMap.get('box').clientWidth - this.dataObj.refMap.get('resize').offsetWidth;

                if (moveLen < this.dataObj.otherParams.lMinWidth) moveLen = this.dataObj.otherParams.lMinWidth;
                if (moveLen > maxT - this.dataObj.otherParams.rMinWidth) moveLen = maxT - this.dataObj.otherParams.rMinWidth;

                this.dataObj.refMap.get('resize').style.left = moveLen;
                this.dataObj.refMap.get('left').style.width = moveLen + 'px';
                this.dataObj.refMap.get('right').style.width = (this.dataObj.refMap.get('box').clientWidth - moveLen - 10) + 'px';
            };
            document.ontouchend =  (evt)=> {
                document.ontouchmove = null;
                document.ontouchend = null;
                this.dataObj.refMap.get('resize').releaseCapture && this.dataObj.refMap.get('resize').releaseCapture();
                this.dataObj.otherParams.lwidth=Math.floor((this.dataObj.refMap.get('left').clientWidth/this.dataObj.refMap.get('box').clientWidth)*100+1)+"%";
            };
            this.dataObj.refMap.get('resize').setCapture && this.dataObj.refMap.get('resize').setCapture();
            return false;
        };
    }
    public cfgWidth(options:any):void{
        this.dataObj.otherParams.lwidth=options.lwidth;
    }
}