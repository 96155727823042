import {createApp, provide, ref} from "vue";
import installElementPlus from '../plugins/element';
import installJhComponent from '../plugins/jhComponent';
import Dialog from '../components/base_comp/dialog/Dialog.vue'
import utils from '../utils/Jh.core';
import config from './config'
import store from '../store';
import router from "../router";
import axios from "axios";
// @ts-ignore
import {export_json_to_excel,export_mul_head_excel} from '../excel/Export2Excel.js';
import animated from "animate.css";
import globalTools from "@/utils/rule";
import addLangProps from '../plugins/lang/lang';
const dialogApp={
    create(options:any) {
        return new Promise((resolve, reject) => {
            let defaults = {
                dialogDiv: "myAlert",
                showDialog: true
            };
            options = Object.assign({}, defaults, options);

            const dialogApp=createApp(Dialog)
                            .use(store)
                            .use(animated)
                            .use(router);
            installElementPlus(dialogApp,ref);
            installJhComponent(dialogApp);
            utils.Tools.initCtx(dialogApp);
            config.bindAppProps(dialogApp,[
                {key:'$params',value:options},
                {key:'$axios',value:axios},
                {key:'utils',value:utils},
                {key:'excelUtils',value:export_json_to_excel},
                {key:'valid',value:globalTools.valid},
                {key:'addLangProps',value:addLangProps},
                {key:'exportMulHeadExcel',value:export_mul_head_excel}
            ]);
            let divEle = document.createElement("div");
            divEle.setAttribute("id",options.dialogDiv);


            document.body.appendChild(divEle);
            const vm=dialogApp.mount(divEle);
            resolve(vm);
        });
    }
};
export default dialogApp;