import BaseBean from "@/utils/BaseBean";

export interface IConditionDataObj {
    utilInst:ConditionUtil
    refMap:Map<string,any>
    conditionArr:Array<any>
    otherParams:any
}

export default class ConditionUtil extends BaseBean{
    public dataObj:IConditionDataObj;
    public props:any;
    public context:any;

    constructor(proxy:any,dataObj:IConditionDataObj,props:any,context:any) {
        super(proxy);
        this.dataObj=dataObj;
        this.props=props;
        this.context=context;
    }
}