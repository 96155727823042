import {addProperty as enAddProperty} from './en';
import {addProperty as zhAddProperty} from './zh';
let addLangProps=(obj:any)=>{
    for(let key of Object.keys(obj)){
        switch (key) {
            case 'zh':zhAddProperty(obj[key]);break;
            case 'en':enAddProperty(obj[key]);break;
        }
    }

}
export default addLangProps;