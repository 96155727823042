import E from "wangeditor";
import Editor from 'wangeditor';
import UtilPub from "@/utils/UtilPub";
import Const from "@/utils/Const";
import store from '@/store'
import AliOss from './aliyun.oss'
class NotificationContent {
    ctx:any
    title:string
    position:string= "bottom-right"
    message:string
    duration:number= 3000
    constructor(title:string,position:string,message:string,duration:number,ctx:any) {
        this.title=title;
        if(position)this.position=position;
        this.message=message;
        if(duration!=0)this.duration=duration;
        this.ctx=ctx;
    }
    getOptions():any{
        return{
            title:this.title?this.title:this.ctx.$t('tools.tip'),
            position:this.position,
            message:this.message?this.message:this.ctx.$t('tools.success'),
            duration:this.duration
        }
    }
}

export default class Tools {
    static ctx:any;
    //从vuex中获取属性值
    static getPropFromVuex(propName:string):any{
        return store.getters[propName];
    }
    //动画是否开启
    static isAnimationOpen(name:string):any{
        let result=false;
        if(sessionStorage.getItem('animations')){
            result=(sessionStorage.getItem('animations') as string).indexOf(name)>-1;
            if(localStorage.getItem(name)){
                result=localStorage.getItem(name)=='true' || localStorage.getItem(name)=='1';
            }
        }
        return result;
    }
    static getAloneServerInfo():string{
        return UtilPub.decrypt(sessionStorage.getItem('aloneServerInfo') as string);
    }
    static initCtx(app:any):void{
        Tools.ctx=app.config.globalProperties;
    } 
    static success(options?:any):void{
        let notificationContent=new NotificationContent('','','',3000,this.ctx);
        let params = Object.assign({}, notificationContent.getOptions(), options);
        Tools.ctx.$notify.success(params);
    }
    static warning(options?:any):void{
        let notificationContent=new NotificationContent(this.ctx.$t('tools.warn'),'','警告信息',3000,this.ctx);
        let params = Object.assign({}, notificationContent.getOptions(), options);
        Tools.ctx.$notify.warning(params);
    }
    static error(options?:any):void{
        let notificationContent=new NotificationContent(this.ctx.$t('tools.fail'),'',this.ctx.$t('tools.failMsg'),3000,this.ctx);
        let params = Object.assign({}, notificationContent.getOptions(), options);
        Tools.ctx.$notify.error(params);
    }
    static info(options?:any):void{
        let notificationContent=new NotificationContent(this.ctx.$t('tools.tip'),'','消息提醒',3000,this.ctx);
        let params = Object.assign({dangerouslyUseHTMLString: true}, notificationContent.getOptions(), options);
        Tools.ctx.$notify.info(params);
    }
    static configBox(options?:any):void{
        let title = Tools.ctx.$t('tools.tip');
        let message = Tools.ctx.$t('tools.deleteNote');
        let type = "warning";
        if (options && options.title) title = options.title;
        if (options && options.message) message = options.message;
        Tools.ctx.$confirm(message, title, {
            confirmButtonText: Tools.ctx.$t('confirm'),
            cancelButtonText: Tools.ctx.$t('cancel'),
            dangerouslyUseHTMLString: true,
            type: type
        }).then(() => {
            if (options && options.sureFn) options.sureFn();
        }).catch((err:any) => {
                Tools.ctx.$message({type: 'info',message: Tools.ctx.$t('tools.cancelOperate')});
            if (options && options.cancelFn) options.cancelFn();
        });
    }

    static defDateRange():any{
        return[{
            text: '当天',
            value: new Date()
        },{
            text: '最近一周',
            value: (() => {
                const end = new Date();
                const start = new Date();
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                return [start, end]
            })()
        }, {
            text: '最近一个月',
            value: (() => {
                const end = new Date();
                const start = new Date();
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                return [start, end]
            })()
        }, {
            text: '最近三个月',
            value: (() => {
                const end = new Date();
                const start = new Date();
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                return [start, end]
            })()
        }]
    }
    static buildWangEditorField(options?:any):Editor{
        options=Object.assign({uploadSaveType:Tools.getPropFromVuex('uploadSaveType'),height:200},options);
        const editField = new E(options.editRef);
        if(options.uploadSaveType==1){
            editField.config.customUploadImg = async (files:any, insert:any) => {
                const res = await AliOss.editorOssUpload(files[0],editField)
            }
        }else{
            editField.config.uploadImgServer = Tools.getAloneServerInfo()+"/attach/uploadFiles";
            editField.config.uploadFileName = 'commonFile';
            editField.config.uploadImgParams = {
                uploadType:'editorPic'
            }
            editField.config.uploadImgHeaders[Const.jfAccessToken]=sessionStorage.getItem(Const.jfAccessToken) as string
        }

        editField.config.zIndex = 300;
        editField.config.height = options.height;
        editField.create();
        editField.txt.clear();
        return editField;
    }
}