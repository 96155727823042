import Axios from "@/utils/axios";
import config from "@/utils/config";
import ApiHelper from "@/utils/api/ApiHelper";
export default class FinanceProviderApi extends ApiHelper{
    static buildUrl(url:string):string{
        if(config.isCloud)url=config.modelTypes.financeProvider+url;
        return url;
    }

    //构造汽车品牌树形数据
    static async getTreeData(params?:any):Promise<any>{
        let result=await Axios.$$post({ url: this.buildUrl("/brand/custom"), params: Object.assign({operateType:'getTreeData'},params) });
        return JSON.parse(result.treeData);
    }
    //查询所有品牌
    static async getAllPp(params?:any):Promise<any>{
        let result=await Axios.$$post({ url: this.buildUrl("/brand/custom"), params: Object.assign({operateType:'getAllPp'},params) });
        return this.buildSelectData(result);
    }
    //查询所有系列
    static async getAllXl(params?:any):Promise<any>{
        let result=await Axios.$$post({ url: this.buildUrl("/brand/custom"), params: Object.assign({operateType:'getAllXl'},params) });
        return this.buildSelectData(result);
    }
    //查询所有车系
    static async getAllCx(params?:any):Promise<any>{
        let result=await Axios.$$post({ url: this.buildUrl("/brand/custom"), params: Object.assign({operateType:'getAllCx'},params) });
        return this.buildSelectData(result);
    }
    //查询指定id的所有孩子节点
    static async getChildData(params?:any):Promise<any>{
        let result=await Axios.$$post({ url: this.buildUrl("/brand/custom"), params: Object.assign({operateType:'getChildData'},params) });
        return this.buildSelectData(result);
    }
    //保存品牌-系列-车系
    static async saveSimpleForm(params?:any):Promise<any>{
        return await Axios.$$post({ url: this.buildUrl("/brand/custom"), params: Object.assign({operateType:'saveSimpleForm'},params) });
    }
    //--------------------------------------params
    //构造树形数据
    static async getMenuTreeData(params?:any):Promise<any>{
        let result=await Axios.$$post({ url: this.buildUrl("/params/custom"), params: Object.assign({operateType:'buildTreeData'},params) });
        return JSON.parse(result.treeData);
    }
    //初始化参数维护页面数据
    static async initParamsPageData(params?:any):Promise<any>{
        let result=await Axios.$$post({ url: this.buildUrl("/params/custom"), params: Object.assign({operateType:'initPageData'},params) });
        return JSON.parse(result.resultData);
    }
    //保存类别
    static async saveParamsForm(params?:any):Promise<any>{
        return await Axios.$$post({ url: this.buildUrl("/params/custom"), params: Object.assign({operateType:'saveParamsForm'},params) });
    }
    //构造指定类别下拉框数据(可传入type或者types，type表示查询单个，types表示查询多个，用,隔开)
    static async buildParamSelectData(params?:any):Promise<any>{
        let result=await Axios.$$post({ url: this.buildUrl("/params/custom"), params: Object.assign({operateType:'buildParamSelectData'},params) });
        if(params.type){
            return this.buildSelectData(result);
        }else if(params.types){
            let map:Map<string,any>=new Map();
            let types=params.types.split(',');
            types.forEach((type:string)=>{
                let data=[] as any;
                result.resultData[type].forEach((item:any,index:number)=>{
                    data.push({value:item.id,label:item.name,item:item});
                })
                map.set(type,data);
            })
            return map;
        }
        return [];
    }
    //改变值顺序
    static async changeOrder(params?:any):Promise<any>{
        return await Axios.$$post({ url: this.buildUrl("/params/custom"), params: Object.assign({operateType:'changeOrder'},params) });
    }
    //--------------------------------------合同模板
    //删除合同模板附件
    static async delContractTemplateAttach(params?:any):Promise<any>{
        return await Axios.$$post({ url: this.buildUrl("/contractTemplate/custom"), params: Object.assign({operateType:'delContractTemplateAttach'},params) });
    }
    //复制合同模板附件
    static async copyContractTemplateGroup(params?:any):Promise<any>{
        return await Axios.$$post({ url: this.buildUrl("/contractTemplate/custom"), params: Object.assign({operateType:'copyContractTemplateGroup'},params) });
    }

    //--------------------------------------公司
    //公司下拉框数据
    static async companySelectData(params?:any):Promise<any>{
        let result=await Axios.$$post({ url: this.buildUrl("/company/custom"), params: Object.assign({operateType:'getAllData'},params) });
        return this.buildSelectData(result);
    }
    //--------------------------------------经销商
    //经销商下拉框数据
    static async agentSelectData(params?:any):Promise<any>{
        let result=await Axios.$$post({ url: this.buildUrl("/agent/custom"), params: Object.assign({operateType:'getAllData'},params) });
        return this.buildSelectData(result);
    }
    //经销商页面初始化下拉框数据
    // static async agentInitPageData(params?:any):Promise<any>{
    //     return await Axios.$$post({ url: this.buildUrl("/agent/custom"), params: Object.assign({operateType:'initPageData'},params) });
    // }
    //匹配车型
    static async ppcx(params?:any):Promise<any>{
        return await Axios.$$post({ url: this.buildUrl("/agent/custom"), params: Object.assign({operateType:'ppcx'},params) });
    }
    //匹配合同
    static async ppht(params?:any):Promise<any>{
        return await Axios.$$post({ url: this.buildUrl("/agent/custom"), params: Object.assign({operateType:'ppht'},params) });
    }
    //--------------------------------------金融方案
    //方案公式项
    static async jrfaExpressionItems(params?:any):Promise<any>{
        return await Axios.$$post({ url: this.buildUrl("/jrfaExpressionItem/custom"), params: Object.assign({operateType:'jrfaExpressionItems'},params) });
    }
    //保存某个金融方案的某个公式项设置
    static async saveJrfaExpressionItemCfg(params?:any):Promise<any>{
        return await Axios.$$post({ url: this.buildUrl("/jrfaExpressionItem/custom"), params: Object.assign({operateType:'saveJrfaExpressionItemCfg'},params) });
    }
    //同步公式项
    static async syncExpression(params?:any):Promise<any>{
        return await Axios.$$post({ url: this.buildUrl("/jrfaExpressionItem/custom"), params: Object.assign({operateType:'syncExpression'},params) });
    }
    //处理复制金融方案保存事件
    static async saveCopyJrfa(params?:any):Promise<any>{
        return await Axios.$$post({ url: this.buildUrl("/jrfaExpressionItem/custom"), params: Object.assign({operateType:'saveCopyJrfa'},params) });
    }
    //删除指定金融方案下的指定公式项
    static async delJrfaExpressionItem(params?:any):Promise<any>{
        return await Axios.$$post({ url: this.buildUrl("/jrfaExpressionItem/custom"), params: Object.assign({operateType:'delJrfaExpressionItem'},params) });
    }
    //--------------------------------------业务办理
    //产品试算初始化
    static async cpssInit(params?:any):Promise<any>{
        return await Axios.$$post({ url: this.buildUrl("/jrfaExpressionItem/custom"), params: Object.assign({operateType:'cpssInit'},params) });
    }
    //过滤金融方案
    static async filterJrfa(params?:any):Promise<any>{
        return await Axios.$$post({ url: this.buildUrl("/jrfaExpressionItem/custom"), params: Object.assign({operateType:'filterJrfa'},params) });
    }
    //方案试算
    static async cpss(params?:any):Promise<any>{
        return await Axios.$$post({ url: this.buildUrl("/jrfaExpressionItem/custom"), params: Object.assign({operateType:'cpss'},params) });
    }
    //还款计划
    static async hkjh(params?:any):Promise<any>{
        return await Axios.$$post({ url: this.buildUrl("/jrfaExpressionItem/custom"), params: Object.assign({operateType:'hkjh'},params) });
    }
}