// @ts-ignore
import {createRouter, createWebHistory, NavigationGuardNext, RouteLocationNormalized, Router, RouteRecordRaw} from 'vue-router';
import utils from '@/utils/Jh.core';
import store  from '../store/';
import config from "@/utils/config";
import otherRoute from "@/router/otherRoute";

const Welcome = () => import('@/views/sysviews/welcome/Welcome.vue');

declare global {
    interface Window {
        customEvent: Array<any>;
        routeMap: Map<string, any>;
    }
}
let userRoutes:Array<RouteRecordRaw> = [
    {path: '/login', name: 'login', component: () => import('@/views/login/Login.vue')},
    {path: '/lockScreen', name: 'lockScreen', component: () => import('@/views/sysviews/yunwei/lockScreen/LockScreen.vue')},
    {path: '/',name: 'Main',redirect: '/welcome',meta: {icon: 'el-icon-message', title: '主界面'},component: () => import('@/components/layout/Main.vue'),children: []},
    {path: '/404', name: '404', component: () => import('@/views/sysviews/404/404.vue')}
];
if(otherRoute && otherRoute.length>0)userRoutes=userRoutes.concat(otherRoute);

class SystemRouter {
    public static buildRoutes(userMenus:any,routeMap:Map<string,any>):void{
        for (let i = 0; i < userMenus.length; i++) {
            if (userMenus[i].children) {
                SystemRouter.buildRoutes(userMenus[i].children, routeMap);
            } else {
                let routItem =SystemRouter.buildLeafRouteItemInfo({
                    id: userMenus[i].id,
                    page: userMenus[i].page,
                    path: userMenus[i].path,
                    icon: userMenus[i].meta.icon,
                    pic: userMenus[i].meta.pic,
                    title: userMenus[i].meta.title,
                    prop: userMenus[i].meta.prop,
                    show: userMenus[i].meta.show,
                    type: userMenus[i].meta.type
                });
                routeMap.set(routItem.path, routItem);
                // @ts-ignore
                userRoutes[2].children.push(routItem);
            }
        }
    }
    public static buildLeafRouteItemInfo(options:any):any{
        let routeItem:RouteItem = {path: options.path, name: options.id,meta:{},component: {}};
        let meta:Meta= {icon: options.icon,pic: options.pic,title: options.title,prop: options.prop,show: options.show,type: options.type,page: options.page};
        routeItem.meta=meta;
        if (options.page && !options.page.startsWith("http")){
            if(options.page=='/listEngine/ListEngine'){
                routeItem.component = async () => await import(`@/components/base_comp${options.page}`);
            }else{
                routeItem.component = async () => await import(`@/views${options.page}`);
            }
        }else{
            routeItem.component = async () => await import('@/components/base_comp/Util.vue');
        }
        return routeItem;
    }
    public static buildPortalsToRoute(portals:any,routeMap:Map<string,any>):any{
        let portalComponents:any = {};
        portals.forEach(function (item:any) {
            portalComponents[item.id] = SystemRouter.getComponentByPage(item.page)
        }, this);
        let welComeRoute = {
            path: '/welcome',
            name: 'welcome',
            meta: {icon: 'el-icon-menu', title: utils.Tools.ctx.$t('tab.homePage')},
            components: Object.assign({}, {default: Welcome}, portalComponents)
        };
        routeMap.set(welComeRoute.path, welComeRoute);
        // @ts-ignore
        userRoutes[2].children.push(welComeRoute);
        userRoutes.forEach(item=>routeMap.set(item.path, item));
    }
    public static getComponentByPage(page:string):any{
        return () => import(`@/views${page}`);
    }
}

let router:Router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: userRoutes
});

let excludePath=config.clientPathArr?config.clientPathArr:[];

router.beforeEach(async (to:RouteLocationNormalized, from:RouteLocationNormalized, next:NavigationGuardNext) => {
    if(excludePath.indexOf(to.path)==-1 && !to.path.endsWith('.html')){
        let lockScreen:ILockScreen=JSON.parse(sessionStorage.getItem('lockScreen') as string);
        if(lockScreen && lockScreen.lockScreen==true && ['/lockScreen','/login','/register'].indexOf(to.path)==-1){
            next('/lockScreen');
        }else{
            if (to.path == '/login' || to.path == '/register') {
                sessionStorage.getItem("userInfo") == null?next():next('/');
            } else {
                if (sessionStorage.getItem("userInfo") == null) {
                    utils.Tools.info({message: utils.Tools.ctx.$t('login.loginNote')});
                    next('/login');
                } else {
                    let children:any=router.options.routes[2].children;
                    if (children.length == 0) {
                        await utils.Api.loadUserMenus().then(res => {
                            store.commit("setNeedCorp", res.needCorp);
                            store.commit("setNeedDept", res.needDept);
                            store.commit("setNeedPerson", res.needPerson);
                            store.commit("setUseDataLevel", res.useDataLevel);
                            store.commit("setUploadSaveType", res.uploadSaveType);
                            store.commit("setMsgReceivePermission", res.hasMessageReceivePermission);
                            store.commit("setChangePersonalInfoPermission", res.hasChangePersonalInfosPermission);
                            let userMenus = JSON.parse(res.userMenus);
                            let portals = res.portals;
                            let routeMap=new Map<string,any>();

                            SystemRouter.buildRoutes(userMenus,routeMap);
                            SystemRouter.buildPortalsToRoute(portals,routeMap);
                            window['routeMap']=routeMap;
                            routeMap.has(to.path) ? next(to.path) : next('/404');
                        }).catch((err:any)=>{
                            utils.Tools.info({message: utils.Tools.ctx.$t('networkWrong')});
                            sessionStorage.removeItem(utils.Const.jfAccessToken);
                            sessionStorage.removeItem("userInfo");
                            location.reload();
                        });
                    }
                    if(to.fullPath!=store.getters.curRoute){
                        store.commit("setCurrentRoute", to.fullPath);
                        userRoutes.forEach(item=>router.addRoute(item));
                        next({...to, replace: true });
                    }else{
                        next()
                    }
                }
            }
        }
    }else{
        next()
    }
})
export default router;