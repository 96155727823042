import AMapLoader from "@amap/amap-jsapi-loader";
import { ElLoading,ElMessage } from 'element-plus'
let mapUtils= {
    buildMapInst:(options:any)=>{
        const loading = ElLoading.service({lock: true, text: 'Loading', background: 'rgba(0, 0, 0, 0.7)'});
        return new Promise((resolve, reject) => {
            AMapLoader.load({
                key:'e649ecd0052d9edd4312ed435e0e12d1',
                version:"2.0",
                plugins:['AMap.ToolBar','AMap.Driving','AMap.Geocoder'],
                AMapUI:{
                    version:"1.1",
                    plugins:[],
                },
                Loca:{
                    version:"2.0.0"
                },
            }).then(async (AMap)=>{
                let center=[106.517219,29.610101];
                if(options.center)center=options.center;
                let searchAutoComplete={},geocoder={};
                let mapInst = new AMap.Map(options.container,{
                    viewMode:"3D",
                    // zoom:5,
                    zoom:16,//级别
                    zooms:[2,22],
                    center:center,
                });
                AMap.plugin('AMap.AutoComplete', function(){
                    let autoOptions = {
                        city: '全国'
                    };
                    searchAutoComplete= new AMap.AutoComplete(autoOptions);
                });
                geocoder = new AMap.Geocoder({
                    city: "全国",
                });
                let defaultMarkerPoints=[
                    {lng:106.517219,lat:29.610101,title:'涉外商务大厦',AMap:AMap,mapInst:mapInst}
                ]
                let markerPoints=[] as Array<any>;
                if(options.markerPoints){
                    options.markerPoints.forEach((item:any)=>{
                        let pointInfo=Object.assign({AMap:AMap,mapInst:mapInst},item);
                        markerPoints.push(pointInfo);
                    })
                }else{
                    markerPoints=defaultMarkerPoints;
                }
                for(let item of markerPoints){
                    mapUtils.buildMarker(item);
                }
                loading.close();
                resolve({AMap:AMap,mapInst:mapInst,searchAutoComplete:searchAutoComplete,geocoder:geocoder});//该方法的返回值
            }).catch(e=>{
                console.log(e);
                ElMessage.error('地图初始化失败');
                loading.close();
            })
        })
    },
    //params  {AMap:AMap,mapInst:mapInst,lng:111,lat:222}
    buildMarker:(options:any)=>{
        let marker = new options.AMap.Marker({
            position:[options.lng,options.lat],//位置
            title: options.title?options.title:''
        })
        options.mapInst.add(marker);//添加到地图
        return marker;
    },
    getAddressByLngLat:(options:any)=>{
        options.geocoder.getAddress(options.lngLat, function(status:string, result:any) {
            if (status === 'complete' && result.regeocode) {
                // console.log(result)province  city  district  street streetNumber
                let address = result.regeocode.formattedAddress;
                let province=result.regeocode.addressComponent.province;
                let district=result.regeocode.addressComponent.district;
                if(options.fn)options.fn({address:address,province:province,district:district})
            }else{
                ElMessage.error('根据经纬度查询地址失败');
            }
        });
    }
}
export default mapUtils;