import {reactive, toRefs, onMounted,onBeforeMount,getCurrentInstance, provide, nextTick, defineComponent, computed} from 'vue'
import DialogUtil,{IDialogDataObj} from "@/components/base_comp/dialog/dialogUtil";
const DialogHelper = defineComponent({
    name: "Dialog",
    setup(props,context){
        let {proxy}=getCurrentInstance() as any;
        provide('dialogInst', proxy);
        const utils=proxy.utils;

        let engineParams=proxy.$params;
        let dataObj:IDialogDataObj=reactive<IDialogDataObj>({
            utilInst:{} as any,
            refMap:new Map(),
            customClass:engineParams.dialogDiv+"_class",
            modelType:'',
            engineRef:null,
            engine:null,
            engineParams:engineParams,
            fullscreenLoading: false,
            options: {
                title: proxy.$t('tools.tip'),
                dWidth: "60%",
                initHeight:'',
                contentHeight: '',

                animationDelay:'0s',
                animationDuration:'0.8s',

                showFooterBtn: false,
                fullscreen: true,
                closeModel: false,
                showMaxRecover: true
            },
            dialogVisible: false,
            otherParams:{}
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new DialogUtil(proxy,dataObj);

            if(utils.Tools.isAnimationOpen('dialogAnimation'))dataObj.options.animationDelay='0.6s';
        })
        onMounted(()=>{
            nextTick(async()=>{
                dataObj.utilInst.initDialogParams(engineParams);
                dataObj.utilInst.buildDialogContain(engineParams);

            })
        })

        const bottomHandler=async (operType:string)=>{
            if (engineParams.ownerComp && engineParams.ownerComp[operType]){
                let result=await engineParams.ownerComp[operType](dataObj.engineRef);
                if(result)dataObj.dialogVisible=false;
            }else{
                dataObj.dialogVisible=false;
            }
        }
        //关闭动作处理
        const doClose=()=>{
            document.body.removeChild(document.getElementById(engineParams.dialogDiv) as any);
            let divs:any=document.getElementsByClassName(engineParams.dialogDiv+"_class");
            for(let i=0;i<divs.length;i++){
                if(divs[i].parentNode.parentNode)document.body.removeChild(divs[i].parentNode.parentNode as any);
            }
            if (engineParams.ownerComp && engineParams.ownerComp['cancelHandler']){
                engineParams.ownerComp['cancelHandler'](dataObj.engineRef)
            }
        }
        const close=()=>{}
        const closed=()=>{doClose();}
        const open=()=>{}
        const opened=async()=> {
            dataObj.options.initHeight=(dataObj.refMap.get('content').offsetHeight+5)+'px';
        }
        //最大化
        const maxHandler=()=>{
            let contentHeight=window.innerHeight-74;
            if(dataObj.options.showFooterBtn)contentHeight=contentHeight-(3+32+20);
            dataObj.options.contentHeight=contentHeight+'px';
            dataObj.options.fullscreen=true;
            dataObj.utilInst.cfgListEngineContentHeight(true);
        }
        //恢复常态
        const recoveryHandler=()=>{
            dataObj.options.fullscreen=false;
            dataObj.options.contentHeight=dataObj.options.initHeight;
            dataObj.utilInst.cfgListEngineContentHeight(true);
        }
        return{
            ...toRefs(dataObj),doClose,close,closed,open,opened,bottomHandler,maxHandler,recoveryHandler
        }
    },
    components: {}
});
export default DialogHelper;