import BaseBean from "@/utils/BaseBean";

interface ITbCol {
    labels:Array<string>
    fields:Array<string>
}
export interface IGridTableDataObj{
    utilInst:any
    refMap:Map<string,any>
    selRowId:any
    tbTitle:string
    loading: boolean
    hasSelection:boolean
    tbData: Array<any>
    tbCols: ITbCol
    currentPage: number
    total: number
    maxTbHeight:number
    params:any
    otherParams:any
}
export default class GridTableUtil extends BaseBean{
    public dataObj:IGridTableDataObj;
    public props:any;
    public context:any;
    constructor(proxy:any,dataObj:IGridTableDataObj,props:any,context:any) {
        super(proxy);
        this.dataObj=dataObj;
        this.props=props;
        this.context=context;
    }
    //设置表格列信息
    public setTbColumnInfoBySlot():void{
        const labels:Array<string> = [];
        const fields:Array<string> = [];
        let tbCols=this.context.slots.tbCols();

        for (let i = 0; i < tbCols.length; i++) {
            if('_tbCols'==tbCols[i].key){
                let gridColumns=tbCols[i].children;
                for (let j = 0; j < gridColumns.length; j++) {
                    if(typeof(gridColumns[j].type)!='object')continue;
                    if(gridColumns[j].props.type)continue;
                    let label=gridColumns[j].props.label;
                    let prop=gridColumns[j].props.prop;
                    if(label)labels.push(label);
                    if(prop)fields.push(prop);
                    if(!prop){
                        let cols=[];
                        if(gridColumns[j].children)cols=gridColumns[j].children.default();
                        for(let k=0;k<cols.length;k++){
                            if(cols[k].props && cols[k].props.label)labels.push(cols[k].props.label);
                            if(cols[k].props && cols[k].props.prop)fields.push(cols[k].props.prop);
                        }
                    }
                }
            }else{
                if(tbCols[i].children && !this.dataObj.params.multiple){
                    for (let j = 0; j < tbCols[i].children.length; j++) {
                        if(typeof(tbCols[i].children[j].type)!='object')continue;
                        const props=tbCols[i].children[j].props;
                        if(props && props.type && props.type=='selection'){
                            this.dataObj.params.multiple=true;
                            break;
                        }
                    }
                }
            }
        }
        this.dataObj.tbCols.labels = labels;
        this.dataObj.tbCols.fields = fields;
    }
    public setTbColumnInfoByColumnList():void{
        const labels:Array<string> = [];
        const fields:Array<string> = [];
        let columnList=this.dataObj.params.columnList;
        columnList.forEach((item:any,index:number)=>{
            labels[index]=item.label;
            fields[index]=item.prop;
        })
        this.dataObj.tbCols.labels = labels;
        this.dataObj.tbCols.fields = fields;
        // console.log(this.dataObj.tbCols)
    }
}