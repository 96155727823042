
import OSS from 'ali-oss';
import Tools from './Tools';
import UtilPub from './UtilPub';
import config from "@/utils/config";
import Api from "@/utils/api/Api";


export default class AliOss{
    static async doUpload(params:any):Promise<any>{
        let res=await Api.getAliOssKey({});//从后台拿取凭证
        let ossInst= new OSS({
            region: res.region,
            accessKeyId: res.keyId,
            accessKeySecret: res.keySecret,
            stsToken: res.secretToken,
            bucket: res.bucketName,
            endpoint: res.endpoint,
            timeout: 120000  // 设置超时时间
        })
        ossInst.multipartUpload(params.uploadName, params.file, {
            progress: function(p:any) {
                params.proxy.otherParams.uploadPercentage= Math.floor(p * 100);
            },
            // partSize: 1000 * 1024,//设置分片大小
            // timeout: 120000,//设置超时时间
        }).then(async ({res, name}:{res:any,name:string}) => {
            if (res && res.status == 200) {
                await params.proxy.utilInst.buildParamsYun(res,params.uploadName,params,params.loading);
            }else{
                Tools.info({message: (res.msg?res.msg:"上传失败!")});params.loading.close();
            }
        }).catch((err:any) => {
            console.log(err)
            Tools.error({message:"上传至阿里云失败"+err});params.loading.close();
        });
    }
    static async editorOssUpload(file:any,editor:any):Promise<any>{
        let res=await Api.getAliOssKey({});
        let ossInst= new OSS({
            region: res.region,
            accessKeyId: res.keyId,
            accessKeySecret: res.keySecret,
            stsToken: res.secretToken,
            bucket: res.bucketName,
            endpoint: res.endpoint
        })
        return new Promise((resolve, reject) => {
            let type=config.type?config.type:'product';
            let uploadName=`${type}`+'/editor/'+UtilPub.guid()+'-'+file.name;
            ossInst.multipartUpload(uploadName, file, {
                progress: function(p:number) {

                }
            }).then(async ({res, name}:{res:any,name:string}) => {
                if (res && res.status == 200) {
                    let serverPath=res.requestUrls[0];
                    if(serverPath.indexOf('?')>-1)serverPath=res.requestUrls[0].substr(0,res.requestUrls[0].indexOf('?'));
                    editor.cmd.do('insertHTML', `<img src="${serverPath}" alt="">`);
                }else{
                    Tools.info({message: (res.msg?res.msg:"上传失败!")});
                }
                resolve(res);
            }).catch((err:any) => {
                console.log(`阿里云OSS上传图片失败回调`, err);
                reject({result:false});
            });
        })
    }
}