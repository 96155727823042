import BaseBean from "@/utils/BaseBean";
import {inject} from "vue";

export interface IEditTableDataObj {
    utilInst:EditTableUtil
    editParams:any
    fields:Array<any>
    insert:Array<any>
    update:Array<any>
    delete:Array<any>
    detailTypeRef:any
    detailType:any
}
export default class EditTableUtil extends BaseBean{

    editTableOwner = inject('EditTableOwner') as any;
    public dataObj:IEditTableDataObj;
    public props:any;
    public context:any;
    constructor(proxy:any,dataObj:IEditTableDataObj,props:any,context:any) {
        super(proxy);
        this.dataObj=dataObj;
        this.props=props;
        this.context=context;
    }
    public async gridLoaded(res:any):Promise<void>{
        this.resetArray();
        this.props.gridLoaded(res);
    }
    public async addDetail():Promise<any>{
        if(this.callEvent('addDetail', {}))return;
        let row = {EDIT:true} as any;
        this.dataObj.fields.forEach((field:string)=> {
            row[field] = "";
        }, row);
        if (!await this.props.beforeAdd({row:row,parentInst:this.props.parentInst})) return false;
        if (!await this.props.beganEdit({parentInst:this.props.parentInst})) return false;
        if(await this.setAllRowCanNotEdit())this.dataObj.detailTypeRef.tbData.push(row);
    }
    public async delDetail(row:any, index:number):Promise<void>{
        if(this.callEvent('delDetail', {row, index}))return;
        let idField = this.dataObj.editParams.idField;
        if (!await this.props.beforeDel({row:row, index:index,parentInst:this.props.parentInst})) return;
        this.dataObj.detailTypeRef.tbData.splice(index, 1);

        if (row[idField]) this.dataObj.delete.push(row[idField]);
        this.deleteFromInsert(row);
        this.deleteFromUpdate(row);
        await this.props.afterDel({row, index,parentInst:this.props.parentInst})
    }
    public async clickTableRowHandler(options:any):Promise<void>{
        if(this.context.attrs['onRowClick'])return;
        let flag=true;
        if (this.dataObj.editParams.readOnly) return;
        if (options.row.EDIT) return;
        options.parentInst=this.props.parentInst
        if (!await this.props.beganEdit(options)) return;
        flag=await this.dataObj.utilInst.setAllRowCanNotEdit();
        if(flag)this.dataObj.detailTypeRef.tbData[options.row.rowIndex].EDIT = !options.row.EDIT;
        if (flag && !this.dataObj.editParams.readOnly) await this.props.afterBeganEdit(options);
    }
    public async setAllRowCanNotEdit():Promise<boolean>{
        let idField = this.dataObj.editParams.idField;
        let tbData = this.dataObj.detailTypeRef.tbData;
        for (let i = 0; i < tbData.length; i++) {
            if (tbData[i].EDIT) {
                if (!await this.props.beforeEndEdit({row:tbData[i], index:i,detailType:this.dataObj.detailType,parentInst:this.props.parentInst})) return false;
                tbData[i].EDIT = false;
                if (tbData[i][idField]) {
                    this.dataObj.update.push(tbData[i]);
                } else {
                    this.dataObj.insert.push(tbData[i]);
                }
            }
        }
        this.dataObj.detailTypeRef.tbData = tbData;
        return true;
    }
    public async clearDetailData():Promise<void>{
        let tbData=this.dataObj.detailTypeRef.tbData;
        let params={tbData:tbData,parentInst:this.props.parentInst};
        if (!await this.props.beforeClearData(params)) return;
        while (tbData.length!=0){
            let row=tbData.shift();
            await this.delDetail(row,0);
        }
        this.dataObj.update.length=0;
        this.dataObj.insert.length=0;
        let _params={deleteArr:this.dataObj.delete,parentInst:this.props.parentInst};
        await this.props.afterClearData(_params);
    }
    public async up():Promise<void>{
        if(this.callEvent('up', {}))return;
        this.doUpDown('up');
    }
    public async down(context:any):Promise<void>{
        if(this.callEvent('down', {}))return;
        this.doUpDown('down');
    }
    public doUpDown(type:string):void{
        let grid=this.dataObj.detailTypeRef;
        let selection=grid.getSelection() as any;
        if(selection.length==1){
            let rowIndex=selection[0].rowIndex;
            if('up'==type){
                if(rowIndex!=0){
                    let data=this.dataObj.detailTypeRef.tbData;
                    [data[rowIndex-1],data[rowIndex]]=[data[rowIndex],data[rowIndex-1]]
                }
            }else{
                let data=this.dataObj.detailTypeRef.tbData;
                if(rowIndex!=data.length-1)[data[rowIndex+1],data[rowIndex]]=[data[rowIndex],data[rowIndex+1]]
            }
        }
    }
    public deleteFromInsert(row:any):void{
        this.utils.UtilPub.delItemFromArr(this.dataObj.insert,row);
    }
    public deleteFromUpdate(row:any):void{
        this.utils.UtilPub.delItemFromArr(this.dataObj.update,row);
    }
    public resetArray():void{
        this.dataObj.insert=[];this.dataObj.update=[];this.dataObj.delete=[];
    }
    public callEvent(eventName:string,options:any):boolean{
        let _eventName='on'+this.utils.UtilPub.upFirst(eventName);
        if(this.context.attrs[_eventName]){
            if(!options.parentInst)options.parentInst=this.props.parentInst;
            this.context.emit(eventName,options);
            return true;
        }
        return false;
    }
}