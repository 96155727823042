import BaseBean from "@/utils/BaseBean";
import {nextTick} from 'vue';
export interface IComboGridDataObj {
    utilInst:ComboGridUtil
    refMap:Map<string,any>
    visible:boolean
    readOnly:boolean
    txtField:string
    idField:string
    comboText: string
    searchFieldsArray:Array<any>
    gridParam: any
    comboParam:any
    otherParams:any
}
export default class ComboGridUtil extends BaseBean{
    public dataObj:IComboGridDataObj
    public props:any;
    public context:any;
    constructor(proxy:any,dataObj:IComboGridDataObj,props:any,context:any) {
        super(proxy);
        this.dataObj=dataObj;
        this.props=props;
        this.context=context;
    }

    public async init(comboParam:any,modelValue:any):Promise<void>{

        if(comboParam.idField)this.dataObj.idField=comboParam.idField;
        if(comboParam.txtField)this.dataObj.txtField=comboParam.txtField;

        if(comboParam.searchFieldsArray){
            this.dataObj.searchFieldsArray=comboParam.searchFieldsArray;
        }else{
            this.dataObj.searchFieldsArray=this.dataObj.refMap.get('gridTable').tbCols.fields;
        }
        this.dataObj.gridParam.queryParam.searchFieldsArray=this.dataObj.searchFieldsArray;
        if(comboParam.readOnly)this.dataObj.readOnly=comboParam.readOnly;

        if(comboParam.modelMethod && modelValue && !this.dataObj.comboText)await this.getTextById(modelValue,comboParam);
    }

    public async comboGridValueChange(newValue:any,oldValue:any,modelValue:any,comboParam:any,context:any):Promise<void>{

        if(newValue){

            if(comboParam.modelMethod && !this.dataObj.comboText)await this.getTextById(modelValue,comboParam);

            if(comboParam.formRef)await comboParam.formRef.validateField(this.dataObj.comboParam.comboId);
        }else{
            this.dataObj.comboText = '';
        }

        if(null!=newValue)context.emit('change', newValue, oldValue);
    }

    public async getTextById(modelValue:any,comboParam:any):Promise<void>{
        if (!modelValue)return ;
        if (comboParam.multiple) {
            if(comboParam.mulComboText)this.dataObj.comboText = comboParam.mulComboText;
        } else {

            if(comboParam.content){
                let item=comboParam.content.find((row:any) => row[this.dataObj.idField] == modelValue);
                if(item)this.dataObj.comboText = item[this.dataObj.txtField];
            }else{
                if(comboParam.comboText){
                    this.dataObj.comboText = comboParam.comboText;
                }else{
                    await nextTick(async ()=>{

                        let modelPathArr=comboParam.modelMethod.split('/');
                        let modelPath="/"+modelPathArr[1];
                        let params= Object.assign({},comboParam,{id: modelValue,comboType:'comboGrid',operateType:'getTextById'});

                        const res=await this.utils.Api.postRequest({url: modelPath + "/custom",params: params});
                        if(res.result)this.dataObj.comboText = res.comboText;
                    })
                }
            }
        }
    }

    public dealCancel(row:any,updateKey:string):void{
        let nowValue:any=this.props.modelValue;
        if(this.dataObj.comboText.indexOf(row[this.dataObj.txtField])>-1){
            if(this.dataObj.comboText==row[this.dataObj.txtField]){//只有一个
                this.dataObj.comboText='';
            }else if(this.dataObj.comboText.endsWith(row[this.dataObj.txtField])){//逗号向前
                this.dataObj.comboText = this.dataObj.comboText.replace(new RegExp(','+row[this.dataObj.txtField]),'');
            }else{//逗号向后
                this.dataObj.comboText = this.dataObj.comboText.replace(new RegExp(row[this.dataObj.txtField]+','),'');
            }
        }
        if(nowValue.indexOf(row[this.dataObj.idField])>-1){
            if(nowValue==row[this.dataObj.idField]){//只有一个
                this.context.emit(updateKey,'');
            }else if(nowValue.endsWith(row[this.dataObj.idField])){//逗号向前
                nowValue = nowValue.replace(new RegExp(','+row[this.dataObj.idField]),'');
                this.context.emit(updateKey,nowValue);
            }else{//逗号向后
                nowValue = nowValue.replace(new RegExp(row[this.dataObj.idField]+','),'');
                this.context.emit(updateKey,nowValue);
            }
        }
    }
    public async emitEvent(eventName:string,...params:any):Promise<void>{

        if(params)params[params.length]=this.dataObj.comboParam.comboId;
        else params[0]=this.dataObj.comboParam.comboId;

        if(this.props.bindEvent[eventName]){
            if(params)await this.props.bindEvent[eventName](...params);
            else await this.props.bindEvent[eventName]();
        }else{
            if(params) this.context.emit(eventName,...params);//触发事件
            else this.context.emit(eventName);//触发事件
        }
    }
}