import {Commit} from "vuex";
import {RouteLocationNormalizedLoaded} from "vue-router";
import utils from '@/utils/Jh.core';
interface IUserTabState {
    visitedTabsView:Array<any>
}
let userTabState:IUserTabState = {
    visitedTabsView: []
}
interface ActionContext{
    commit: Commit,
    state:IUserTabState
}


export default {
    state:userTabState,
    mutations: {
        ['addTabView'](state:IUserTabState, view:RouteLocationNormalizedLoaded) {
            if (state.visitedTabsView.find((n:RouteLocationNormalizedLoaded) => n.path === view.path))return;
            state.visitedTabsView.push({ title: view.meta.title, path: view.path });
        },
        ['closeTabView'](state:IUserTabState, view:any) {
            for (let [i, v] of state.visitedTabsView.entries()) {
                if (v.path === view.path || v.title === view.title) {
                    state.visitedTabsView.splice(i, 1);break;
                }
            }
        },

        ['closeAllTabViews'](state:IUserTabState) {
            state.visitedTabsView.length=0;
            state.visitedTabsView.push({ title: utils.Tools.ctx.$t('tab.homePage'), path: "/welcome" })
        },
        ['recoverViews'](state:IUserTabState,viewArr:Array<any>) {
            let exist:boolean=state.visitedTabsView.some((item:any)=>item.path==='/welcome');
            if(!exist)state.visitedTabsView.push({ title: utils.Tools.ctx.$t('tab.homePage'), path: "/welcome" });
            for (let i=0;i<viewArr.length;i++) {
                state.visitedTabsView.push({ title: viewArr[i].title, path: viewArr[i].path});
            }
        },
        ['closeOtherTabViews'](state:IUserTabState,view:any) {
            state.visitedTabsView.length=0;
            if(view.path!='/welcome')state.visitedTabsView.push({ title: utils.Tools.ctx.$t('tab.homePage'), path: "/welcome" })
            state.visitedTabsView.push({ title: view.title, path: view.path });
        }
    },
    actions: {
        addVisitedTabsView({ commit }:ActionContext, view:RouteLocationNormalizedLoaded) {
            if(view.path!='/' && view.path!='/layer')commit('addTabView', view)
        },
        closeSpeTabsView({ commit, state }:ActionContext, view:RouteLocationNormalizedLoaded) {
            return new Promise((resolve, reject) => {
                if(view.path!='/welcome'){
                    commit('closeTabView', view)
                    resolve([...state.visitedTabsView])
                }
            })
        },
        closeAllVisitedTabsView({ commit }:ActionContext,options:any) {
            commit('closeAllTabViews')
        },
        recoverViews({ commit }:ActionContext, viewArr:Array<any>) {
            commit('recoverViews',viewArr)
        },
        closeOtherTabsView({ commit }:ActionContext, view:RouteLocationNormalizedLoaded) {
            commit('closeOtherTabViews', view)
        },
        checkTabsViewExist({ commit,state }:ActionContext, view:RouteLocationNormalizedLoaded) {
            return new Promise((resolve, reject) => {
                if(view.path!='/welcome'){
                    let exist=state.visitedTabsView.some((n:RouteLocationNormalizedLoaded) => n.path === view.path);
                    resolve(exist);
                }
            })
        },

        changeSelfName({ commit }:ActionContext, newName:string) {
            commit('changeSelfName', newName)
        }
    },
    getters: {

        visitedTabsView: (state:IUserTabState) => state.visitedTabsView
    }
}