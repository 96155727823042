import Axios from "@/utils/axios";
import config from "@/utils/config";
import ApiHelper from "@/utils/api/ApiHelper";
export default class ToolsProviderApi extends ApiHelper{
    static buildUrl(url:string):string{
        if(config.isCloud)url=config.modelTypes.toolsProvider+url;
        return url;
    }
    // //-------------------------------------------------------金杯操作手册--------------------------------------------------
    // //-------------------------金杯操作手册后台-------------------------
    // //加载所有品牌
    static async getAllToolBrand(params?:any):Promise<any>{
        let result= await Axios.$$post({ url: this.buildUrl("/toolBrand/custom"), params: Object.assign({operateType:'getAllData'},params) });
        return this.buildSelectData(result)
    }
    // //加载所有的车型
    static async getAllCx(params?:any):Promise<any>{
        let result= await Axios.$$post({ url: this.buildUrl("/cx/custom"), params: Object.assign({operateType:'getAllData'},params) });
        return this.buildSelectData(result)
    }
    // 批量操作车型
    static async batchCx(params?:any):Promise<any>{
        return await Axios.$$post({ url: this.buildUrl("/cx/custom"), params: Object.assign({operateType:'batchOperate'},params) });
    }
    // 批量操作车型
    static async batchServiceStationTxl(params?:any):Promise<any>{
        return await Axios.$$post({ url: this.buildUrl("/serviceStationTxl/custom"), params: Object.assign({operateType:'batchOperate'},params) });
    }
    //根据手册id查询手册地址（预览手册）
    static async queryManualUrl(params?:any):Promise<any>{
        return  await Axios.$$post({ url: this.buildUrl("/manual/custom"), params: Object.assign({operateType:'queryManualUrl'},params) });
    }
    //-------------------------金杯操作手册H5-------------------------
    //加载手册的品牌，不知道为什么取名车型
    static async clientQueryManualCx(params?:any):Promise<any>{
        let result= await Axios.$$post({ url: this.buildUrl("/clientInfManual/custom"), params: Object.assign({operateType:'queryManualCx'},params) });
        let data=[] as any;
        result.data.forEach((item:any,index:number)=>{
            data.push({value:item.id,label:item.name,item:item});
        })
        return data
    }
    //按品牌加载该品牌下的车型
    static async clientQueryManualCxByBrandId(params?:any):Promise<any>{
        let result= await Axios.$$post({ url: this.buildUrl("/clientInfManual/custom"), params: Object.assign({operateType:'queryManualCxByBrandId'},params) });
        let data=[] as any;
        result.data.forEach((item:any,index:number)=>{
            data.push({value:item.id,label:item.name,item:item});
        })
        return data
    }
    //按车型加载该车型下的使用手册
    static async queryManualByCxId(params?:any):Promise<any>{
        const res= await Axios.$$post({ url: this.buildUrl("/clientInfManual/custom"), params: Object.assign({operateType:'queryManualByCxId'},params) });
        return res.data;
    }
    //加载金杯、斯威、系能源的手册(按品牌加载所有车型的手册-使用、操作手册)
    static async queryManualByType(params?:any):Promise<any>{
        return await Axios.$$post({ url: this.buildUrl("/clientInfManual/custom"), params: Object.assign({operateType:'queryManualByType'},params) });
    }
    //根据手册id查询手册地址（预览手册）
    static async clientQueryManualInfo(params?:any):Promise<any>{
        return  await Axios.$$post({ url: this.buildUrl("/clientInfManual/custom"), params: Object.assign({operateType:'queryManualInfo'},params) });
    }
    //修改个人信息
    static async clientUpdateInfo(params?:any):Promise<any>{
        return  await Axios.$$post({ url: this.buildUrl("/clientInfManual/custom"), params: Object.assign({operateType:'updateInfo'},params) });
    }
    //H5服务站用户登录
    static async clientLogin(params?:any):Promise<any>{
        return await Axios.$$post({ url: this.buildUrl("/clientInfManual/custom"), params: Object.assign({operateType:'login'},params) });
    }
    //初始化服务站页面数据
    static async initServiceStationPageData(params?:any):Promise<any>{
        return await Axios.$$post({ url: this.buildUrl("/clientInfManual/custom"), params: Object.assign({operateType:'initServiceStationPageData'},params) });
    }
    //根据条件筛选服务站
    static async clientQueryServiceStation(params?:any):Promise<any>{
        return await Axios.$$post({ url: this.buildUrl("/clientInfManual/custom"), params: Object.assign({operateType:'queryServiceStation'},params) });
    }
    //加载指定省份下所有的城市
    static async clientGetCitiesByProvince(params?:any):Promise<any>{
        return await Axios.$$post({ url: this.buildUrl("/clientInfManual/custom"), params: Object.assign({operateType:'getCitiesByProvince'},params) });
    }
    static async getAllVideoType(params?:any):Promise<any>{
        let result= await Axios.$$post({ url: this.buildUrl("/clientInfManual/custom"), params: Object.assign({operateType:'getAllVideoType'},params) });
        return this.buildSelectData(result)
    }
    //加载视频
    static async clientQueryVideo(params?:any):Promise<any>{
        return await Axios.$$post({ url: this.buildUrl("/clientInfManual/custom"), params: Object.assign({operateType:'clientQueryVideo'},params) });

        // let data=[] as any;
        // result.data.forEach((item:any,index:number)=>{
        //     data.push({value:item.id,label:item.name,item:item});
        // })
        // return data
    }
}