import {Commit} from "vuex";

interface IOthersState {
    needDept: boolean,
    needCorp: boolean,
    needPerson: boolean,
    useDataLevel: boolean,
    uploadSaveType: number,
    hasMessageReceivePermission:boolean,
    hasChangePersonalInfosPermission:boolean,
    curRoute:any
}
let othersState:IOthersState = {
    needDept: true,
    needCorp: true,
    needPerson: true,
    useDataLevel: true,
    uploadSaveType:0,
    hasMessageReceivePermission:false,
    hasChangePersonalInfosPermission:false,
    curRoute:'',
}
interface ActionContext{
    commit: Commit,
    state:IOthersState
}
export default {
    state:othersState,
    mutations: {
        ['setNeedCorp'](state:IOthersState, needCorp:boolean) {
            state.needCorp = needCorp;
        },
        ['setNeedDept'](state:IOthersState, needDept:boolean) {
            state.needDept = needDept;
        },
        ['setNeedPerson'](state:IOthersState, needPerson:boolean) {
            state.needPerson = needPerson;
        },
        ['setUseDataLevel'](state:IOthersState, useDataLevel:boolean) {
            state.useDataLevel = useDataLevel;
        },
        ['setUploadSaveType'](state:IOthersState, uploadSaveType:number) {
            state.uploadSaveType = uploadSaveType;
        },
        ['setMsgReceivePermission'](state:IOthersState, permission:boolean) {
            state.hasMessageReceivePermission = permission;
        },
        ['setChangePersonalInfoPermission'](state:IOthersState, permission:boolean) {
            state.hasChangePersonalInfosPermission = permission;
        },
        ['setCurrentRoute'](state:IOthersState, curRoute:any) {
            state.curRoute = curRoute;
        }
    },
    actions: {
    },
    getters: {
        needDept: (state:IOthersState) => state.needDept,
        needCorp: (state:IOthersState) => state.needCorp,
        needPerson: (state:IOthersState) => state.needPerson,
        useDataLevel: (state:IOthersState) => state.useDataLevel,
        uploadSaveType: (state:IOthersState) => state.uploadSaveType,
        hasMessageReceivePermission: (state:IOthersState) => state.hasMessageReceivePermission,
        hasChangePersonalInfosPermission: (state:IOthersState) => state.hasChangePersonalInfosPermission,
        curRoute: (state:IOthersState) => state.curRoute
    }
}