export default class ApiHelper {
    //根据请求结果构建select下拉数据
    static buildSelectData(result:any):any{
        let data=[] as any;
        result.resultData.forEach((item:any,index:number)=>{
            data.push({value:item.id,label:item.name,item:item});
            // data.push({value:item.id,label:item.name,item:item,disabled:true});
        })
        return data;
    }
}