import axios, {AxiosInstance, AxiosRequestConfig, AxiosResponse} from "axios";
import Tools from "@/utils/Tools";
import Const from "@/utils/Const";
import UtilPub from "@/utils/UtilPub";
import config from './config'

class AxiosUtil{

    public static notEncryptUri:Array<string>=[
        '/user/loginInit',
        '/downLoadResourceFile',
        '/downLoadFile'
    ]
    public static isNotEncryptData(uri:string):boolean{

        return false;
    }
}
//创建axios实例
const axiosInst:AxiosInstance = axios.create({
    timeout: 1*60*60*1000
});
axiosInst.interceptors.request.use((reqConfig:AxiosRequestConfig): AxiosRequestConfig => {
        //01）往header中放入encryptData
        if(AxiosUtil.isNotEncryptData(reqConfig.url as string)){
            reqConfig.headers?reqConfig.headers["encryptData"] = false:'';
        }else{
            reqConfig.headers?reqConfig.headers["encryptData"] = sessionStorage.getItem('encryptData') as string:'';
        }

        const userToken = sessionStorage.getItem(Const.jfAccessToken);

        if(userToken)reqConfig.headers?reqConfig.headers[Const.jfAccessToken] = userToken:'';

        const applications = config.applications.toString();
        // @ts-ignore
        reqConfig.headers['applications'] = applications;
        // @ts-ignore
        reqConfig.headers['currentLang'] = localStorage.getItem('currentLang')?localStorage.getItem('currentLang'):'zh-CN';
        return reqConfig;
    },
    (error) => {
        return Promise.reject(error);
    }
);

axiosInst.interceptors.response.use((res:AxiosResponse) => {
        const invalidCode=[Const._0001,Const._0002,Const._0009,Const._0010]
        if(res.headers[Const.code.toLowerCase()]==Const._0000 && res.headers[Const.jfAccessToken.toLowerCase()]){
            sessionStorage.setItem(Const.jfAccessToken, res.headers[Const.jfAccessToken.toLowerCase()]);//更新本地Jf-Access-Token
            window.localStorage.setItem(Const.jmReportAccessToken, res.headers[Const.jfAccessToken.toLowerCase()]);//更新本地积木的token，可删除
        }else if(invalidCode.includes(res.headers[Const.code.toLowerCase()])){//token验证失败token(0001)没有传入(0002)token过期(0009)非法访问（0010），跳转到登录页
            if(res.headers[Const.code.toLowerCase()]==Const._0010){
                alert('亲，你在恶意访问哦，惩罚你不能使用不系统一分钟以上')
            }
            sessionStorage.removeItem(Const.jfAccessToken);
            sessionStorage.removeItem("userInfo");
            location.reload();//由于userInfo被去除了。所以reload整个页面会在路由拦截那里经过判断，然后跳转到登录页
        }
        return Promise.resolve(res);
    },
    (err) => {
        console.log(err);
        return Promise.reject(err);
    }
);

export default class Axios {
    //发送post请求
    static $$post(options:any):Promise<any>{
        return new Promise((resolve, reject) => {
            let url=Tools.getAloneServerInfo()+options.url;
            if(!options.aloneServer){
                if(config.contextPath)url=config.contextPath +options.url;
                url=config.baseURL+url;
            }
            let params =options.params;
            if(params && sessionStorage.getItem('encryptData')=='true' && !AxiosUtil.isNotEncryptData(options.url)){
                params = UtilPub.encrypt(JSON.stringify(params));
            }
            axiosInst({
                method: "post",
                url: url,
                headers:{'Content-Type':'application/json'},
                data:params
            }).then((res:any) => {
                if(res.data && res.headers[Const.code.toLowerCase()]==Const._0000){
                    if(sessionStorage.getItem('encryptData')=='true')res.data=JSON.parse(UtilPub.decrypt(res.data));
                    if (!res.data.result && res.data.msg)Tools.error({ message: res.data.msg });
                    resolve(res.data);
                }
            }).catch((err:any) => {
                Tools.error();
                reject(err)
            });
        });
    }

    static $$upload(options:any):Promise<any>{
        return new Promise((resolve, reject) => {
            axiosInst.post(options.url,options.params.formData)
                     .then((res:any)=> {
                         if(res.data && sessionStorage.getItem('encryptData')=='true' && !AxiosUtil.isNotEncryptData(options.url)){
                             res.data=JSON.parse(UtilPub.decrypt(res.data));
                         }
                         resolve(res.data);
                     }).catch((err:any) => {
                         Tools.error();
                         reject(err);
                     });
        });
    }
    //文件下载。
    static $$downLoad(options:any):Promise<any>{
        return new Promise((resolve, reject) => {
            let url= Tools.getAloneServerInfo()+ options.url;
            if(!options.aloneServer){
                if(config.contextPath)url=config.contextPath+options.url;
                url=config.baseURL+url;
            }
            let params =options.params;
            if(params && sessionStorage.getItem('encryptData')=='true' && !AxiosUtil.isNotEncryptData(options.url)){
                params = UtilPub.encrypt(JSON.stringify(params));
            }
            axiosInst({
                url: url,
                method: 'post',
                responseType: 'blob',
                headers:{'Content-Type':'application/json'},
                data: params
            }).then((res:any) => {
                if(Const._0000==res.headers[Const.downloadcode.toLowerCase()]){
                    const blob = res.data
                    const reader = new FileReader()
                    reader.readAsDataURL(blob)
                    reader.onload = (e: ProgressEvent<FileReader>) => {
                        const a = document.createElement('a')||{};
                        a.download = options.params.fileName;
                        // @ts-ignore
                        a.href=e.target.result;
                        document.body.appendChild(a);
                        a.click();
                        document.body.removeChild(a);
                    }
                }else{
                    Tools.error({message:Tools.ctx.$t('downloadFileFail')});
                }
                resolve(res);
            }).catch((err:any) => {
                Tools.error();
            });
        });
    }
}