import BaseBean from "@/utils/BaseBean";
import {inject} from "vue";

export interface IEditTableItemDataObj {
    utilInst:EditTableItemUtil
    otherParams:any
}
export default class EditTableItemUtil extends BaseBean{
    public dataObj:IEditTableItemDataObj;
    public props:any;
    public context:any;
    constructor(proxy:any,dataObj:IEditTableItemDataObj,props:any,context:any) {
        super(proxy);
        this.dataObj=dataObj;
        this.props=props;
        this.context=context;
    }
}