import BaseBean from "@/utils/BaseBean";
import {useRoute} from "vue-router";
import {nextTick} from 'vue'
export interface IListEngineDataObj {
    utilInst:any
    refMap:Map<string,any>
    mainComp:any
    fullscreenLoading:boolean
    exportType:number
    exportExcelVisible: boolean
    backData: any
    collapseTitle:string
    queryCondition:Array<string>
    queryHeight:number
    queryConditionHeight: number
    listButtons: Array<any>
    listParam: any
    otherParams:any
}
export default class ListEngineUtil extends BaseBean{
    route = useRoute();
    public dataObj:IListEngineDataObj
    public props:any
    public context:any
    constructor(proxy:any,dataObj:IListEngineDataObj,props:any,context:any) {
        super(proxy);
        this.dataObj=dataObj;
        this.props=props;
        this.context=context;
    }

    public initListEngineParams():void{
        let pageParams=this.context.attrs.pageList||{};
        let title=this.route.meta.title;
        if(pageParams.title)title=pageParams.title;
        else if(pageParams.modelComp)title=pageParams.modelComp.title;
        Object.assign(this.dataObj.listParam, pageParams,{title:title});
        if(!this.dataObj.listParam.expandQuery)this.dataObj.queryCondition=[];
    }

    public async buildPage(res:any):Promise<void>{
        let conditionRowNum = 0;
        let rowHeight=39;
        if (this.dataObj.listParam.isShowQueryParam) {
            if(this.context.slots.queryParams){
                let queryParams=this.context.slots.queryParams();
                queryParams.forEach((item:any)=>{if(item.props)conditionRowNum++;})
            }else{
                if(this.dataObj.listParam.isBuildPageFromBack && (this.dataObj.otherParams.conditions.length==0 || this.dataObj.otherParams.componentLoadFlag)){
                    this.dataObj.otherParams.conditions=res.pageInfo.conditions;
                    if(this.dataObj.otherParams.conditions.length>0){
                        if(this.dataObj.otherParams.conditions.length%2==0)conditionRowNum=this.dataObj.otherParams.conditions.length/2;
                        else conditionRowNum=Math.floor(this.dataObj.otherParams.conditions.length/2)+1;
                        this.dataObj.otherParams.querySlots=this.dataObj.otherParams.conditions.map((item:any)=>{
                            return item.javaName;
                        });
                    }
                    if(this.context.slots.extendSlot){
                        let extendSlot=this.context.slots.extendSlot();
                        conditionRowNum=conditionRowNum+extendSlot.length;
                    }
                }
            }
        }
        if(conditionRowNum==0){
            this.dataObj.listParam.isShowQueryParam=false;
            this.dataObj.queryHeight=0;
        }else this.dataObj.queryConditionHeight = conditionRowNum * rowHeight;
    }
    public async loadPageData():Promise<void>{
        let queryParam=this.dataObj.listParam.queryParam;
        queryParam.pageSize = this.dataObj.refMap.get('tbRef').params.pagerParams.pageSize;
        queryParam.currentPage = this.dataObj.refMap.get('tbRef').currentPage;
        queryParam.canPage = this.dataObj.refMap.get('tbRef').params.canPage;
        queryParam.isBuildPageFromBack = this.dataObj.listParam.isBuildPageFromBack;
        let res = await this.utils.Api.postRequest({url: this.dataObj.listParam.modelMethod, params: queryParam});
        if(this.dataObj.listParam.isBuildPageFromBack && res.pageInfo){
            res.pageInfo=JSON.parse(res.pageInfo);

            res=await this.props.formatPageInfo(res);

            if(this.dataObj.listParam.columnList.length==0 || this.dataObj.otherParams.componentLoadFlag){
                this.dataObj.listParam.columnList=res.pageInfo.columns;
                this.dataObj.refMap.get('tbRef').setGridColumns(res.pageInfo.columns);
            }
        }
        await this.buildPage(res);
        if(!this.callEvent('afterPageData',res)){
            this.dataObj.refMap.get('tbRef').setTbData(res.rows);
            this.dataObj.refMap.get('tbRef').setTbDataTotal(res.total);
            this.props.gridLoaded(res);
        }
        this.dataObj.backData = res;
        this.dataObj.listButtons = res.buttons;
        this.dataObj.fullscreenLoading=false;
    }

    public setSpeHeight(height:number,reCalFlag:boolean):void{
        this.dataObj.otherParams.baseCalHeight=height;
        this.setGridTableContentMaxHeight(height);
    }

    public handleChange(val:any):void{
        if (val.length == 1) { //表示展开
            this.dataObj.collapseTitle=this.proxy.$t('listEngine.queryOpenTitle');
            this.dataObj.queryHeight=this.dataObj.queryConditionHeight+this.utils.Const.listEngineCollapseHeight;
        }else{
            this.dataObj.collapseTitle=this.proxy.$t('listEngine.queryFoldTitle');
            this.dataObj.queryHeight=this.utils.Const.listEngineCollapseHeight;
        }
        this.setGridTableContentMaxHeight(this.dataObj.otherParams.baseCalHeight);
    }

    public setGridTableContentMaxHeight(height:number=window.innerHeight):void{
        nextTick(()=>{
            let showTop:boolean=localStorage.getItem('showTop')?localStorage.getItem('showTop')=='true':true;
            if(!showTop)this.utils.Const.topHeight=0;
            let maxTbHeight=height-this.utils.Const.topHeight-this.utils.Const.tabHeight;
            if(this.dataObj.listParam.isShowBtnGroup)maxTbHeight-=this.utils.Const.listEngineBtnHeight;
            if(this.dataObj.listParam.isShowQueryParam)maxTbHeight-=this.dataObj.queryHeight;

            if(this.dataObj.refMap.get('tbRef').params.showTitle)maxTbHeight-=this.utils.Const.tbTitleHeight;
            if(this.dataObj.refMap.get('tbRef').params.showToolBar)maxTbHeight-=this.utils.Const.tbToolBarHeight;
            if(this.dataObj.refMap.get('tbRef').params.canPage)maxTbHeight-=this.utils.Const.tbPagerHeight;
            this.dataObj.refMap.get('tbRef').setMaxTbHeight(maxTbHeight);
        })
    }

    public async showCard(options:any):Promise<void>{
        options = Object.assign({}, {ownerComp: this.proxy,dialogDiv:this.utils.UtilPub.randomString(6)}, this.dataObj.listParam,options);
        let vm = await this.utils.DialogApp.create(options);
        vm.dialogVisible = true;
    }

    public async sureExport():Promise<void>{
        const loading = this.proxy.$loading({lock: true,text: this.proxy.$t('loadMsg'),spinner: 'el-icon-loading',background: 'rgba(0, 0, 0, 0.7)'});
        switch (this.dataObj.exportType) {
            case 0:
                await this.exportExcel(this.dataObj.refMap.get('tbRef').getTbData());
                break;
            case 1:
                let modelPath=this.dataObj.listParam.modelMethod.substr(0,this.dataObj.listParam.modelMethod.lastIndexOf('/'));
                let res = await this.utils.Api.postRequest({url: modelPath + "/exportExcelData", params: {canPage: false}});
                await this.exportExcel(res.rows);
                break;
        }
        this.dataObj.exportExcelVisible = false;
        loading.close();
        this.proxy.$message({showClose: true, message: this.proxy.$t('listEngine.exportSuccess'), type: 'success'});
    }
    public async exportExcel(tbData:any):Promise<void>{
        return new Promise<void>((resolve, reject) => {
            const data = this.formatJson(this.dataObj.refMap.get('tbRef').tbCols.fields, tbData);//处理之后的tbData
            let fields=this.dataObj.refMap.get('tbRef').tbCols.fields;
            let labels=this.dataObj.refMap.get('tbRef').tbCols.labels;
            if (this.context.attrs['exportExcel']) {
                this.context.attrs['exportExcel'](fields, labels,data,this.proxy);
                resolve();
            } else {
                this.proxy.excelUtils(labels, data, this.dataObj.listParam.title + this.proxy.$t('excel'));
                resolve();
            }
        }).catch(err => {
            this.proxy.$message({showClose: true, message: this.proxy.$t('listEngine.exportFail'), type: 'error'});
        });
    }
    public formatJson(fields:any, jsonData:any):void{
        return jsonData.map((rowData:any) => fields.map((field:string) => rowData[field]))
    }

    public async deleteData(row:any):Promise<void>{
        if (!await this.props.beforeDelete(row)) return;
        let modelPath=this.dataObj.listParam.modelMethod.substr(0,this.dataObj.listParam.modelMethod.lastIndexOf('/'));
        const loading = this.proxy.$loading({lock: true,text: this.proxy.$t('loadMsg'),spinner: 'el-icon-loading',background: 'rgba(0, 0, 0, 0.7)'});
        let res = await this.utils.Api.postRequest({url: modelPath + "/delete", params: {id: row[this.dataObj.listParam.idField]}});
        loading.close();
        if(res.result){
            this.utils.Tools.success({message: res.msg});
            await this.props.afterDelete(row);
            this.dataObj.refMap.get('tbRef').queryHandler(false);
        }
    }
    public callEvent(eventName:string,options:any):boolean{
        let _eventName='on'+this.utils.UtilPub.upFirst(eventName);
        if(this.context.attrs[_eventName]){
            this.context.emit(eventName,options);
            return true;
        }
        return false;
    }
}