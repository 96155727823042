export default class Const {
    public static topHeight=46//顶部的高度
    public static tabHeight=34;//tab的高度
    public static listEngineBtnHeight=39;//列表引擎操作按钮高度
    public static listEngineCollapseHeight=31;//列表引擎查询条件折叠框高度

    public static tbTitleHeight=25;//表格标题的高度
    public static tbToolBarHeight=35;//表格工具条的高度
    public static tbPagerHeight=32;//分页工具栏高度

    public static jfAccessToken='Jf-Access-Token';
    public static jmReportAccessToken='JmReport-Access-Token';
    public static code='code'; //放到header中的，前端取的时候必须是小写，否则取不到，放入的时候可以不是小写
    public static downloadcode='downloadcode';


    public static _0000='0000';//成功
    public static _0001='0001';//token验证失败
    public static _0002='0002';//没有传入token
    public static _0009='0009';//token过期
    public static _0010='0010';//非法访问
    public static _9999='9999';//返回结果不验证，直接成功

    //--------------------------------------参数设置
    public static htmb="htmb";//默认合同分组
    public static ywy="ywy";//默认业务员
    public static zjqd="zjqd";//资金渠道
    public static cpfa="cpfa";//产品方案
    //--------------------------------------下拉查询级别类型
    public static current=0;//本级
    public static cascade=1;//本级及下级
    public static all=2;//显示所有

    public static zh='zh';//中文
    public static en='en';//English
}