import Crypoto from "@/utils/EnDecypt";
import {TableColumnCtx} from "element-plus/es/components/table/src/table-column/defaults";
import utils from './Jh.core'
export default class UtilPub {
    constructor(){

    }
    /*字符串加密*/
    public static encrypt(txt:string):string{
        let crypoto:Crypoto=new Crypoto();
        return crypoto.encrypt(txt);
    }
    /*字符串解密*/
    public static decrypt(txt:string):string{
        if(!txt)return '';
        let crypoto:Crypoto=new Crypoto();
        return crypoto.decrypt(txt);
    }
    //随机数
    public static guid():string{
        return  'xxxxxxxx-xxxx' .replace(/[xy]/g, (c)=>{
            let  r = Math.random()*16|0, v = c ==  'x'  ? r : (r&0x3|0x8);
            return  v.toString(16);
        });
    }
    public static randomString(length:number):string{
        let str = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
        let result = '';
        for (let i = length; i > 0; --i)
            result += str[Math.floor(Math.random() * str.length)];
        return result;
    }
    //格式化为yyyy-mm-dd的格式
    public static formatDate(date:string,join:string):string{
        if(!date)return '';
        let d = new Date(date),month = '' + (d.getMonth() + 1),day = '' + d.getDate(),year = d.getFullYear();
        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;
        if(!join)join='-';
        return [year, month, day].join(join);
    }

    public static isNotEmpty(strInput:any):boolean{
        return !(strInput == null || (strInput + "") == "" || !strInput);
    }
    public static isEmpty(strInput:any):boolean{
        return strInput == null || (strInput + "") == "" || !strInput;
    }
    public static isWangEditEmpty(str:any):boolean{
        let num = 0,reg = /<p>(&nbsp;|&nbsp;\s+)+<\/p>|<p>(<br\/>)+<\/p>/g;
        while (num < str.length && str != "")
        {
            num++;
            let k = str.match(reg);
            if (k) {
                str = str.replace(k[0], "");
            }
        }
        return str == "";
    }
    public static isObjectEqual(a:any,b:any):boolean{
        if(!a || !b)return false;
        if(Object.keys(a).length!=Object.keys(b).length)return false;
        if(Object.keys(a).length==0)return false;
        let aProps = Object.getOwnPropertyNames(a);//拿到两个对象的所有key
        let bProps = Object.getOwnPropertyNames(b);
        if (aProps.length != bProps.length) {//看长度是否相等
            return false;
        }

        for (let i = 0; i < aProps.length; i++) {
            let propName = aProps[i];
            if(propName=='__ob__')continue;
            let propA = a[propName];
            let propB = b[propName];
            if ((typeof (propA) === 'object')) {
                let result=this.isObjectEqual(propA, propB);

                if(!result)return false;
            } else if (propA !== propB) {
                return false
            }
        }
        return true
    }

    public static delItemFromArr(arr:any,delItem:any):void{
        let index=arr.findIndex((item:any) => this.isObjectEqual(item,delItem),this);
        if(index!=-1){
            arr.splice(index,1);
        }
    }

    public static phoneValidate(value:string):boolean{
        const reg1 = /^0[1|2|3|4|5|6|7|8|9][1|2|3|4|5|6|7|8|9][1|2|3|4|5|6|7|8|9]-\d{8}$/
        const reg2 = /^1[3|4|5|7|8|9][0-9]\d{8}$/
        return (reg1.test(value) || reg2.test(value));
    }

    public static checkPhone(rule:any, value:string, callback:any,required:boolean):void{
        if (!value) {
            if(required==false){
                callback()
            }else{
                callback("请输入电话号码")
            }
        } else {
            const reg1 = /^0[1|2|3|4|5|6|7|8|9][1|2|3|4|5|6|7|8|9][1|2|3|4|5|6|7|8|9]-\d{8}$/
            const reg2 = /^1[3|4|5|7|8|9][0-9]\d{8}$/

            if (reg1.test(value) || reg2.test(value)) {
                callback()
            } else {
                return callback(new Error('请输入正确的电话号码'))
            }
        }
    }

    public static checkMobilePhone(rule:any, value:string, callback:any,required:boolean):void{
        if (!value) {
            if(required==false){
                callback()
            }else{
                callback("请输入电话号码")
            }
        } else {
            const reg = /^1[3|4|5|7|8|9][0-9]\d{8}$/
            if (reg.test(value)) {
                callback()
            } else {
                return callback(new Error('请输入正确的电话号码'))
            }
        }
    }
    //----------------------------------------animate动画--------------------------------
    //表单Animate动画
    public static formAnimate():any{
        let animate:any={animate__animated:true};
        animate[UtilPub.randomFadeinAnimateStr()]=true;
        return animate;
    }
    //layerAnimate动画
    public static randomLayerAnimate():any{
        let animate:any={animate__animated:true};
        animate[UtilPub.randomAnimateStr()]=true;
        return animate;
    }
    //表格列Animate动画
    public static randomGridColAnimate():string{
        return 'animate__animated '+UtilPub.randomAnimateStr();
    }
    //表格内容Animate动画
    public static randomAnimateStr():string{
        let arr=new Array(18);arr.fill(false);
        let randIndex=Math.floor(Math.random() * arr.length);
        let baseClass=[
            'animate__swing',
            'animate__bounceInDown',
            'animate__bounceInRight',
            'animate__bounceInUp',
            'animate__fadeInDown',
            'animate__fadeInRight',
            'animate__flipInX',
            'animate__flipInY',
            'animate__lightSpeedInRight',
            'animate__rotateIn',
            'animate__rotateInDownLeft',
            'animate__rotateInDownRight',
            'animate__rotateInUpRight',
            'animate__rollIn',
            'animate__slideInDown',
            'animate__slideInUp',
            'animate__slideInLeft',
            'animate__slideInRight'
        ];
        return baseClass[randIndex];
    }
    //进入animate
    public static randomFadeinAnimateStr():string{
        let arr=new Array(6);arr.fill(false);
        let randIndex=Math.floor(Math.random() * arr.length);
        let baseClass=[
            'animate__fadeInRight',
            'animate__fadeInLeft',
            'animate__fadeInBottomRight',
            'animate__fadeInBottomLeft',
            'animate__fadeInTopLeft',
            'animate__fadeInTopRight'
        ];
        return baseClass[randIndex];
    }
    //----------------------------------------animate动画--------------------------------
    //防抖
    public static debounce(callback:Function,wait:number=800):any{
        let timer:any = null;
        let exFn= () => {
            timer&&clearTimeout(timer);
            timer = setTimeout(callback, wait)
        };
        exFn();
    }
    //打开弹出来
    public static async openDialog(options:any):Promise<void>{
        options=Object.assign({},{
            dialogDiv:UtilPub.randomString(6),
            fullscreen:false,
            showFooterBtn:true,
            contentHeight:'400px',
            ownerComp:options.proxy
        },options);

        if(options.fullscreen)options.contentHeight=(window.innerHeight-94)+'px';
        let vm = await options.proxy.utils.DialogApp.create(options);
        vm.dialogVisible = true;
    }

    public static commonValidRule(message:string):TCommonRuleItem{
        return {required: true, message: message, trigger: "blur"}
    }
    public static commonFormValidRule(formRules:Array<TCommonObj>):TFormRule{
        let result:TFormRule={};
        formRules.forEach((formItem:TCommonObj)=>{
            let keys:Array<string>=Object.keys(formItem);
            for(let i=0;i<keys.length;i++){
                result[keys[i]]=[UtilPub.commonValidRule(formItem[keys[i]])];
            }
        }) 
        return result;
    }

    public static formatterStatus(row:any, column:TableColumnCtx<any>, value:any, index:number):string{
        if (0 == value) {
            return utils.Tools.ctx.$t('normal');
        } else if (1 == value) {
            return utils.Tools.ctx.$t('stop');
        }else {
            return "";
        }
    }
    public static formatterYesNoStatus(row:any, column:TableColumnCtx<any>, value:any, index:number):string{
        if (0 == value) {
            return utils.Tools.ctx.$t('yes');
        } else if (1 == value) {
            return utils.Tools.ctx.$t('no');
        }else {
            return "";
        }
    }
    //首字母大写
    public static upFirst(str:string):string{
        return str.charAt(0).toUpperCase() + str.slice(1);
    }
}