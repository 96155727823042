import {RouteRecordRaw} from "vue-router";
let otherRoute:Array<RouteRecordRaw>=[
    // {path: '/clientUseManual', name: 'clientUseManual', component: () => import('@/views/project/tools/manual/client/useManual/ClientUseManual.vue')},
    //为了不该公众号上面的链接地址，这里访问品牌也叫/clientUseManual
    {path: '/clientUseManual', name: 'clientUseManual', component: () => import('@/views/project/tools/manual/client/useManual/brand/Brand.vue')},
    {path: '/clientCx', name: 'clientCx', component: () => import('@/views/project/tools/manual/client/useManual/cx/Cx.vue')},
    {path: '/clientUseManualList', name: 'clientUseManualList', component: () => import('@/views/project/tools/manual/client/useManual/manual/Manual.vue')},

    {path: '/clientRepairManual', name: 'clientRepairManual', component: () => import('@/views/project/tools/manual/client/repairManual/ClientRepairManual.vue')},
    {path: '/clientViewFile', name: 'clientViewFile', component: () => import('@/views/project/tools/manual/client/utils/ViewFile.vue')},
    {path: '/clientServiceStationTxl', name: 'clientServiceStationTxl', component: () => import('@/views/project/tools/manual/client/serviceStationTxl/ClientServiceTxlStationTotal.vue')},

    {path: '/clientSwm', name: 'clientSwm', component: () => import('@/views/project/tools/manual/client/serviceStationTxl/ClientServiceTxlStation.vue')},
    {path: '/clientJx', name: 'clientJx', component: () => import('@/views/project/tools/manual/client/serviceStationTxl/ClientServiceTxlStation.vue')},
    {path: '/clientLogin', name: 'clientLogin', component: () => import('@/views/project/tools/manual/client/login/ClientLogin.vue')},
    {path: '/clientUpdateInfo', name: 'clientUpdateInfo', component: () => import('@/views/project/tools/manual/client/updateInfo/UpdateInfo.vue')},
    {path: '/clientPersonCenter', name: 'clientPersonCenter', component: () => import('@/views/project/tools/manual/client/personCenter/PersonCenter.vue')},

    {path: '/clientRepairChoose', name: 'clientRepairChoose', component: () => import('@/views/project/tools/manual/client/clientRepairChoose/ClientRepairChoose.vue')},
    {path: '/clientRepairVideos', name: 'clientRepairVideos', component: () => import('@/views/project/tools/manual/client/clientRepairVideos/ClientRepairVideos.vue')},
    {path: '/videoPlay', name: 'videoPlay', component: () => import('@/views/project/tools/manual/client/clientRepairVideos/videoPlay/VideoPlay.vue')},
]
export default otherRoute;