let lang={
    login:{
        loginTitle:'user login',
        usercodePlaceholder:'please input user name',
        passwordPlaceholder:'please input user password',
        verifyCodePlaceholder:'please input verify code',
        sliderStartText:'please move it to right',
        sliderSuccessText:'success',
        login:'login',
        register:'register',
        loginSuccessMsg:'Login succeeded',
        loginInitWrong:'Login page initialization error!',
        loginNote:'please login!',
        loginOut:'Log out and log in again',
        loginOutNote:'Are you sure to log off and exit the system?',
        loginOutMsg:'Successfully logged out!',
        completeMsg:'Please complete the login information',
        moveSliderNote:'Please drag the slider to the far right',
        verifyCodeWrong:'Incorrect verification code',
        browerMsg:'To use the system properly, please use the<span style="color: red;">Google</span>browser',
        beforeContent:"'Shineray'",
        afterContent:"'System'",
    },
    tools:{
        tip:'tip',
        warn:'warn',
        fail:'fail',
        success:'Operation succeeded',
        cancelOperate:'The operation has been canceled',
    },
    top:{
        fold_open:'Fold/Open',
        fullScreen:'Full screen',
        menuList:'menu list',
        live2dConfig:'Kanban Settings',
        toggleSidebar:'Toggle Sidebar',
        topToolbar:'Show Top Toolbar',
        menuSwitch:'Menu Layout Toggle',
        menuSwitchTip:'Switch menu layout?',
        animationConfig:'Personalized configuration',
        changePersonalInfos:'Modify personal information',
        logOut:'login out',
        sysPub:'System announcement',
        lockScreen:'Lock screen',
        accessModule:'Module accessed',
        pageAnimation:'Page Animation',
        routerAnimation:'Router Animation',
        themeStyle:'Theme style',
        Enable_Dialog_Background:'Enable Dialog Background',
        Enable_Dialog_Animation:'Enable Dialog Animation',
        title:'explain',
        description:'Check "Auto Effective" and there is no need to save.',
        closeTxt:'I know',
        loginAnimation:'Login',
        mainAnimation:'Home',
        listAnimation:'List',
        formAnimation:'Form',
        defaultTheme:'default',
        darkTheme:'dark',
        lightBlueTheme:'light Blue',
    },
    tab:{
        refresh:'refresh',
        closeCurrent:'Close Current',
        closeOthers:'Close Other',
        closeAll:'Close All',
        closeLeft:'Close all on the left',
        closeRight:'Close all on the right',
        mainPage:'Main Page',
        homePage:'Home Page',
    },
    portal:{
        layout:'portal layout',
        one_columns:'One column',
        two_columns:'Two columns',
        three_columns:'Three columns',
        add_del:'add/delete portals',
        save:'save',
        refresh:'refresh',
        more:'more',
        name:'name',
        saveLayOutTip:'You have not configured any workbenches, are you sure to save?',
    },
    listEngine:{
        loadMsg:'Loading desperately, please wait......',
        operate:'operate',
        edit:'edit',
        delete:'delete',
        exportConfig:'export Config',
        exportCurrent:'export Current Page',
        exportAll:'export All Page',
        exportSuccess:'export Success !',
        exportFail:'export Failed',
        excel:'excel',
        queryFoldTitle:'Query criteria (click to expand)',
        queryOpenTitle:'Query criteria (click to collapse)',
    },
    cardEngine:{
        saveTip:'Are you sure to save?',
        deleteTip:'Are you sure to delete this record？',
        addToDeleteTip:'New status, cannot be deleted！',
    },
    upload:{
        upload:'upload',
        result:'upload result',
        limitNumPre:'Maximum upload',
        limitNumSuf:'Files',
        canNotDel:'Delete not allowed',
        delTipMsg:'Are you sure you want to delete this file?',
        delTip:'Delete tip',
        fileNameLimit:'The length of the uploaded file name cannot be greater than 90',
        fileSizeLimit:'Uploaded file must be less than',
        success:'Upload succeeded',
        fail:'Upload failed',
    },
    importGuid:{
        title:'import data',
        step1:'step 1',
        step2:'step 2',
        step3:'step 3',
        templateDownload:'download Template',
        fillData:'fill data',
        uploadTemplate:'Upload template',
        downloadPre:'Confirm Download',
    },
    editTable:{
        add:'New',
        up:'Up',
        down:'Down',
        operate:'operate',
        delete:'delete',
        title:'detail data',
    },
    combo:{
        placeholder:'please select',
        edit:'edit',
        refresh:'refresh',
        F_CODE:'Code',
        F_NAME:'Name',
    },
    area:{
        searchTip:'Please enter a keyword search',
        detailTitle:'details',
        F_CODE:'CODE',
        F_NAME:'NAME',
    },
    layer:{
        picNotExist:'{picName} not exist',
        operateCfg:'Operation settings',
        move:'move',
        merge:'merge',
        folderName_placeholder:'Please enter the merged folder name',
        icon:'Icon',
        animation:'Animation',
    },
    normal:'normal',
    stop:'stop',
    lock:'lock',
    yes:'yes',
    no:'no',
    man:'man',
    woman:'woman',
    close:'Close',
    closeForever:'Close Forever',
    confirm:'Confirm',
    cancel:'Cancel',
    loadMsg:'Executing, please wait......',
    mainLoadMsg:'Please wait while the data is being prepared......',
    networkWrong:'There\'s a problem with the network',
    deleteNote:'This operation will permanently delete this record. Do you want to continue?',
    _404Msg:'The page you are currently visiting has an error. Please contact the super administrator or log out of the account and log in again',
    unit:'RMB',
    downloadFileFail:'File download failed',
    fullScreenMsg:'Your browser does not support this feature',
}

export let addProperty=(objs:any)=>{
    Object.assign(lang,objs);
}
export default lang