import BaseBean from "@/utils/BaseBean";
import {UploadFile, UploadFiles, UploadRawFile, UploadRequestOptions} from "element-plus/lib/components";
import {inject,nextTick} from "vue";
import config from "@/utils/config";
import {ElementLoading} from "element-plus/lib/components/loading/src/directive";

export interface IUploadDataObj {
    utilInst:UploadUtil
    refMap:Map<string,any>
    resultMsg:string
    uploadDialogVisible:boolean
    dialogImageUrl:string
    fileList:Array<any>,
    uploadParams:IUploadParams
    uploadResultDrawer:boolean
    otherParams:{}
}
export default class UploadUtil extends BaseBean{
    uploadOwner = inject('uploadOwner') as any;
    public dataObj:IUploadDataObj
    public props:any
    public context:any
    constructor(proxy:any,dataObj:IUploadDataObj,props:any,context:any) {
        super(proxy);
        this.dataObj=dataObj;
        this.props=props;
        this.context=context;
    }
    public async beforeRemoveFile(file:UploadFile, fileList:UploadFiles):Promise<boolean>{
        if (file.status!='success')return true;
        if(!this.dataObj.uploadParams.canDelFile){
            this.proxy.$message(this.proxy.$t('upload.canNotDel'));return false;
        }
        return new Promise((resolve, reject) => {
            this.proxy.$confirm(this.proxy.$t('upload.delTipMsg')+file.name, this.proxy.$t('upload.delTip'), {
                confirmButtonText: this.proxy.$t('confirm'),
                cancelButtonText: this.proxy.$t('cancel'),
                type: 'warning'
            }).then(async () => {
                let result = await this.props.beforeRemove(file, fileList);
                if(result)resolve(true);
                else reject(false);
            }).catch(() => {
                this.proxy.$message({type: 'info',message: this.proxy.$t('tools.cancelOperate')});
                reject(false)
            });
        });
    }
    //删除文件
    public async remove(file:any, fileList:UploadFiles):Promise<void>{
        const loading = this.proxy.$loading({lock: true,text: this.proxy.$t('loadMsg'),spinner: 'el-icon-loading',background: 'rgba(0, 0, 0, 0.7)'});
        if (file.status=='success') {
            let res = await this.utils.Api.deleteFile({id:file.id});
            if(res.result){
                this.utils.Tools.success();
                await this.props.afterRemove(file, fileList);
            }
        }
        loading.close();
    }

    public beforeUpload(file:UploadRawFile):boolean{
        if(file.name.length>90){
            this.proxy.$message.error(this.proxy.$t('upload.fileNameLimit'));
            return false;
        }
        const isLarge = file.size / 1024 / 1024 < (this.dataObj.uploadParams.fileSize as number)
        if (!isLarge) {
            this.proxy.$message.error(this.proxy.$t('upload.fileSizeLimit')+this.dataObj.uploadParams.fileSize+'M');
            return false;
        }
        return true;
    }
    public async doUpload(params:UploadRequestOptions):Promise<void>{
        const loading = this.proxy.$loading({lock: true,text: this.proxy.$t('loadMsg'),spinner: 'el-icon-loading',background: 'rgba(0,0,0,0.7)'});
        let file = params.file,fileType = file.type;
        if(this.dataObj.uploadParams.saveType==1){
            let type=config.type?config.type:'product';
            let aliCloudPre=this.dataObj.uploadParams.aliCloudPre?this.dataObj.uploadParams.aliCloudPre:config.aliCloudPre;
            let uploadName =  `${aliCloudPre}/${type}/${this.dataObj.uploadParams.uploadType}/${this.utils.UtilPub.formatDate(new Date(),'/')}/` + this.utils.UtilPub.guid()+file.name.substr(file.name.indexOf('.'));
            if(this.dataObj.uploadParams.belongMaxId){
                uploadName =  `${aliCloudPre}/${type}/${this.dataObj.uploadParams.uploadType}/${this.dataObj.uploadParams.belongMaxId}/` + this.utils.UtilPub.guid()+file.name.substr(file.name.indexOf('.'));
            }
            await this.utils.AliOss.doUpload({uploadName:uploadName,file:file,loading:loading,proxy:this.proxy});
        }else{
            await this.buildParamsSelf(params,loading);
        }
    }

    public async buildParamsYun(res:any,uploadName:string,params:UploadRequestOptions,loading:ElementLoading):Promise<void>{
        let serverPath=res.requestUrls[0];
        if(serverPath.indexOf('?')>-1)serverPath=res.requestUrls[0].substr(0,res.requestUrls[0].indexOf('?'));
        let formData={
            uid:(params.file as any).uid+'',
            preName:params.file.name,
            nowName:uploadName,
            path:serverPath,
            type:this.dataObj.uploadParams.type+'',
            belongMaxId:this.dataObj.uploadParams.belongMaxId,
            belongMinId:this.dataObj.uploadParams.belongMinId,
            uploadType:this.dataObj.uploadParams.uploadType+'',
            fileSize:params.file.size+''
        }
        if (this.uploadOwner.buildUploadParams) await this.uploadOwner.buildUploadParams(formData,params,this.proxy);
        await this.saveUploadInfo({formData:formData,loading:loading});
    }

    public async buildParamsSelf(params:UploadRequestOptions,loading:ElementLoading):Promise<void>{
        let formData = new FormData() as any;
        formData.append('uid',(params.file as any).uid);
        formData.append('commonFile',params.file);
        formData.append('preName',params.file.name);
        formData.append('belongMaxId',this.dataObj.uploadParams.belongMaxId as string);
        formData.append('belongMinId',this.dataObj.uploadParams.belongMinId as string);
        formData.append('uploadType',this.dataObj.uploadParams.uploadType as string);
        formData.append('type',this.dataObj.uploadParams.type+'');
        formData.append('fileSize',params.file.size+'');
        if (this.uploadOwner && this.uploadOwner.buildUploadParams) await this.uploadOwner.buildUploadParams(formData,params,this.proxy);
        await this.saveUploadInfo({formData:formData,loading:loading});
    }

    public async saveUploadInfo(options:any):Promise<void>{
        let res:any,uid:any;
        if(this.dataObj.uploadParams.saveType==1){
            res=await this.utils.Api.saveUploadFiles({url:this.dataObj.uploadParams.action,formData:options.formData});
            uid=options.formData.uid;
        }else{
            let jsonParams={} as any;
            for (let [key, value] of options.formData) {
                if(typeof value!="object") jsonParams[key]=value;
                if('uid'==key) uid=value;
            }
            if(this.dataObj.uploadParams.action){
                options.formData.append('jsonParams',JSON.stringify(jsonParams));
                res=await this.utils.Api.speUploadFile(Object.assign(this.dataObj.uploadParams,{formData:options.formData}));
            }else{
                res=await this.utils.Api.uploadFile({formData:options.formData});
            }
        }
        options.loading.close();
        if(this.context.attrs['uploadResult']){
            (this.context.attrs['uploadResult'] as Function)(res);
        }else{
            if(!res.result){
                this.utils.Tools.info({message: (res.msg?res.msg:this.proxy.$t('upload.fail'))});
                if(res.errorData){
                    this.dataObj.resultMsg=res.errorData;
                    this.dataObj.uploadResultDrawer=true;
                }
            }else{
                this.utils.Tools.success({message: (res.msg?res.msg:this.proxy.$t('upload.success'))});
                if(res.uploadFiles){
                    this.dataObj.fileList.forEach((item:any)=>{
                        if(!item.id){
                            let uploadFiles=res.uploadFiles;
                            for(let i=0;i<uploadFiles.length;i++){
                                if(uid==item.uid){
                                    item.id=uploadFiles[i].id;
                                    item.url=uploadFiles[i].path;break;
                                }
                            }
                        }
                    })
                }
            }
            if(this.context.attrs['afterResult'])  (this.context.attrs['afterResult'] as Function)(res);
        }
    }
}