import UtilPub from "@/utils/UtilPub";
import Const from "@/utils/Const";
import Tools from "@/utils/Tools";
import DialogApp from "@/utils/DialogApp";
import Axios from "@/utils/axios";
import Websocket from "@/utils/Websocket";
import AliOss from "@/utils/aliyun.oss";
import map from "@/utils/map";


import Api from "@/utils/api/Api"
import FinanceProviderApi from "@/utils/api/FinanceProviderApi"
import ToolsProviderApi from "@/utils/api/ToolsProviderApi"
import DemoProviderApi from "@/utils/api/DemoProviderApi"

export default {
    UtilPub,Const,Tools,DialogApp,Axios,Websocket,AliOss,map,

    Api,FinanceProviderApi,ToolsProviderApi,DemoProviderApi
}

