
import GridTableUtil,{IGridTableDataObj} from "@/components/base_comp/gridTable/gridTableUtil";
import {ref, getCurrentInstance, reactive, nextTick, toRefs, onMounted, onBeforeMount, onUpdated, inject, defineComponent, watch} from 'vue';
import Sortable from "sortablejs";
import config from "@/utils/config";
const GridTableHelper = defineComponent({
    name: "GridTable",
    props: {
        queryParam: {//查询条件
            type: Object,
            default:()=>{return{}}
        },
        gridLoaded:{type: Function, default: async (options:any) => {}},
    },

    setup(props:any,context){
        let gridTableOwner = inject('gridTableOwner') as any;
        let {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        const tbRef=ref(null) as any;
        let dataObj:IGridTableDataObj=reactive<IGridTableDataObj>({
            utilInst: {} as any,
            refMap:new Map(),
            selRowId:'',
            tbTitle:'',
            loading: false,
            hasSelection:false,
            tbData: [],
            tbCols: {labels:[],fields:[]},
            currentPage: 1,
            total: 0,
            maxTbHeight:200,
            params:{
                tbId:'defTbId',
                idField:'F_ID',
                title:'',
                showTitle:true,
                showToolBar: false,
                modelMethod:'',
                isShowIndexCol:true,
                autoLoad: true,
                staticData: false,
                dragRow: false,
                dragParent: '',
                showRadio: false,
                multiple: false,
                canPage:true,
                pagerParams: {
                    small:false,
                    pagerCount:7,
                    pageSize: 20,
                    pageSizes: [20, 50, 100, 200],
                    layout:'total, sizes, prev, pager, next, jumper'
                },
                queryParam:{},
                columnList:[]
            },
            otherParams:{

            }
        })
        watch(() => dataObj.params.columnList,async (newValue,oldValue) => {
            if(newValue && newValue.length>0)dataObj.utilInst.setTbColumnInfoByColumnList();
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new GridTableUtil(proxy,dataObj,props,context);
        })
        onMounted(async ()=>{
            await nextTick(async()=>{
                Object.assign(dataObj.params,context.attrs);
                dataObj.utilInst.setTbColumnInfoBySlot();
                if(dataObj.params.autoLoad) await queryHandler(true,null);

                if(dataObj.params.dragRow)dragRow();
                await nextTick(()=>{

                })
            })
        })
        onUpdated(async ()=>{
        })
        const dragRow=()=>{
            let tbody:any=document.querySelector('.el-table__body-wrapper tbody');
            if(dataObj.params.dragParent){
                tbody=document.querySelector('.'+dataObj.params.dragParent+' .el-table__body-wrapper tbody');
            }
            tbody.style.cursor = 'grab';
            Sortable.create(tbody,{
                disabled: false,
                animation:150,
                onEnd(ev:any){
                    if (gridTableOwner && gridTableOwner.dragRow) {
                        gridTableOwner.dragRow({ev: ev});
                    }else{
                        if (gridTableOwner && gridTableOwner.endDragRow)gridTableOwner.endDragRow({data:dataObj.tbData,ev:ev,inst:proxy});
                    }
                }
            });
        }
        const queryHandler=async(isNewQuery:boolean,options:any)=>{
            dataObj.loading = true;
            if(isNewQuery)dataObj.currentPage=1;
            if (!dataObj.params.staticData && dataObj.params.modelMethod) {
                props.queryParam.pageSize = dataObj.params.pagerParams.pageSize;
                props.queryParam.currentPage = dataObj.currentPage;
                props.queryParam.canPage = context.attrs.canPage?context.attrs.canPage:dataObj.params.canPage;

                try {
                    if(options){
                        props.queryParam=Object.assign(props.queryParam, options);
                    }
                    let res = await utils.Api.postRequest({url: dataObj.params.modelMethod, params:props.queryParam});

                    if(res.result){
                        dataObj.loading = false;
                        dataObj.tbData = res.rows;
                        dataObj.total = res.total;
                        props.gridLoaded(res);
                    }else{
                        utils.Tools.error();
                    }
                } catch (res) {
                    console.log(res);
                }
            }

            if (dataObj.loading) dataObj.loading = false;
        }
        const reloadGrid=async (options:any)=>{
            await queryHandler(true,options);
        }
        const indexMethod=(index:number)=>{
            return index+1;
        }
        const rowClassName=({row, rowIndex}: {row: any,rowIndex: number})=>{
            row.rowIndex = rowIndex;
            let selectRows=tbRef.value.store.states.selection.value;
            let index=selectRows.findIndex((row:any)=>{
                return row.rowIndex==rowIndex
            })
            if(index!=-1){
                return 'rowHighLight'
            }else{
                return '';
            }
        }

        const setGridColumns=(columnList:any)=>{
            dataObj.params.columnList=columnList;
        }
        const setMaxTbHeight=(maxTbHeight:number)=>{
            dataObj.maxTbHeight=maxTbHeight;
        }
        const getTbData=()=>{
            return dataObj.tbData?dataObj.tbData:[];
        }
        const setTbData=(tbData:any)=>{
            dataObj.tbData = tbData;
            dataObj.total = tbData.length;
            props.gridLoaded(tbData);
        }
        const setTbDataTotal=(total:number)=>{
            dataObj.total = total;
        }
        const clearTbData=()=> {
            dataObj.total =0;
            dataObj.tbData.splice(0, dataObj.tbData.length)
        }
        const sizeChangeHandler=async (val:number)=>{
            dataObj.params.pagerParams.pageSize = val;
            await queryHandler(true,null);
        }
        const currentChangeHandler=async (val:number)=>{
            dataObj.currentPage = val;
            await queryHandler(false,null);
        }
        const sortChangeHandler=async (column:any, prop:any, order:any)=>{
            props.queryParam.orderField = column.prop;
            let orderType="asc"
            if(column.order=='descending'){
                orderType="desc";
            }
            props.queryParam.orderType =orderType;
            await queryHandler(true,null);
        }


        const rowClickHandler= (row: any)=>{
            dataObj.selRowId=row[dataObj.params.idField];
            if(!dataObj.params.multiple)tbRef.value.clearSelection();
            toggleRowSelection(row);
            nextTick(()=>{
                let _row=getSelection().find((item:any)=>item[dataObj.params.idField]==row[dataObj.params.idField]);
                context.emit("rowClickStatusChange", row,_row?true:false);
            })
        }


        const getSelection=()=>{
            return tbRef.value.store.states.selection.value;
        }

        const toggleRowSelection=(row:any)=>{
            tbRef.value.toggleRowSelection(row);
        }

        return{
            ...toRefs(dataObj),dragRow,queryHandler,reloadGrid,indexMethod,rowClassName,setGridColumns,tbRef,getTbData,setTbData,setTbDataTotal,clearTbData,
            sizeChangeHandler,currentChangeHandler,sortChangeHandler,getSelection,toggleRowSelection,
            rowClickHandler,setMaxTbHeight
        }
    }
});

export default GridTableHelper;