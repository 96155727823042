import BaseBean from "@/utils/BaseBean";

export interface IUnitDataObj {
    utilInst:UnitUtil
    refMap:Map<string,any>
    otherParams:any
}

export default class UnitUtil extends BaseBean{
    public dataObj:IUnitDataObj

    constructor(proxy:any,dataObj:IUnitDataObj) {
        super(proxy);
        this.dataObj=dataObj;
    }
}